import React, { useState } from "react";
import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import RoutineProgressBar from "./RoutineProgressBar";
import AccountActionButton from "../login/AccountActionButton";
import Lineup from "./Lineup";
import TeamCreationButton from "./buttons/TeamCreationButton";
import ResetCurrentActivityButton from "./buttons/ResetCurrentActivityButton";
import StuntShowButton from "./buttons/StuntShowButton";
import PlayPauseButton from "./buttons/PlayPauseButton";
import ZoomButton from "./buttons/ZoomButton";
import RoutineCreationButton from "./buttons/RoutineCreationButton";
import ToggleDragAndRouteButton from "./buttons/ToggleDragAndRouteButton";
import HorizontalLinesShowButton from "./buttons/HorizontalLinesShowButton";
import FeedbackButton from "./buttons/FeedbackButton";
import PlaySpeedButton from "./buttons/PlaySpeedButton";
import { Position } from "../../api/types/routine.types";

const ScreenRoutine: React.FC = () => {
  const [startCoordinate, setStartCoordinate] = useState<Required<Position>>({
    x: 0,
    y: 0
  });
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="ion-align-items-center ion-text-center">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButtons slot="end">
            <FeedbackButton />
            <RoutineCreationButton />
            <TeamCreationButton />
            <AccountActionButton />
          </IonButtons>
          <IonTitle>Cheermaster</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        style={{
          display: "flex",
          flexFlow: "column",
          height: "100%"
        }}
      >
        <IonGrid
          style={{
            display: "flex",
            flexFlow: "column",
            flex: "1 1 auto",
            height: "100%",
            maxHeight: "100%",
            overflow: "hidden"
          }}
        >
          <IonRow style={{ flex: "0 1 auto" }}>
            <IonCol className="padding-y-0">
              <RoutineProgressBar />
            </IonCol>
          </IonRow>
          <IonRow
            style={{ flex: "0 1 auto" }}
            className="button-bar ion-align-items-center"
          >
            <IonCol sizeLg="1" sizeSm="2" sizeXs="2" className="padding-y-0">
              <PlayPauseButton />
            </IonCol>
            <IonCol sizeLg="2" sizeSm="4" sizeXs="5" className="padding-y-0">
              <PlaySpeedButton />
            </IonCol>
            <IonCol sizeLg="6" sizeSm="4" sizeXs="4" className="padding-y-0">
              <ResetCurrentActivityButton />
              <StuntShowButton />
              <ToggleDragAndRouteButton />
              <HorizontalLinesShowButton />
            </IonCol>
            <IonCol sizeLg="3" sizeSm="2" sizeXs="2" className="padding-y-0">
              <ZoomButton setStartCoordinate={setStartCoordinate} />
            </IonCol>
          </IonRow>
          <IonRow style={{ flex: "1 1 auto" }}>
            <IonCol className="padding-y-0">
              <Lineup
                startCoordinate={startCoordinate}
                setStartCoordinate={setStartCoordinate}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ScreenRoutine;
