import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getFirestore,
  onSnapshot,
  Query
} from "firebase/firestore";

export const createCollection = <T = DocumentData>(collectionName: string) =>
  collection(getFirestore(), collectionName) as CollectionReference<T>;

export const subscribeCollection = <DataType>(
  query: Query<DataType>,
  onNewSnapshot: (snapshotData: DataType[]) => void
) =>
  onSnapshot(
    query,
    (querySnapshot) => {
      const newSnapshotData = querySnapshot.docs.map((doc) => {
        const newData = { ...doc.data(), id: doc.id };
        return newData as DataType;
      });
      onNewSnapshot(newSnapshotData);
    },
    (error) => console.error(error)
  );

export const subscribeDocument = <T extends { id: string }>(
  document: DocumentReference<T>,
  onNewSnapshot: (newSnapshot: T) => void
) =>
  onSnapshot(document, (newDoc: DocumentSnapshot<T>) => {
    const newData = { ...newDoc.data(), id: newDoc.id };
    onNewSnapshot(newData as T);
  });
