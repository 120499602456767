import { IonButton, IonContent, IonPopover } from "@ionic/react";
import { BlockPicker } from "react-color";
import tinyColor from "tinycolor2";

const COLORS = [
  "#e91e63",
  "#9c27b0",
  "#3f51b5",
  "#03a9f4",
  "#009688",
  "#8bc34a",
  "#ffeb3b",
  "#ff9800",
  "#795548",
  "#607d8b"
];

const ColorPicker = ({
  cheerleaderInitials,
  currentColor,
  onColorChange
}: {
  cheerleaderInitials: string;
  currentColor: string;
  onColorChange: (color: string) => void;
}) => (
  <IonButton
    style={{
      width: "35px",
      height: "35px",
      marginRight: "10px",
      backgroundColor: currentColor,
      "--background": currentColor,
      borderRadius: "50%",
      color: tinyColor(currentColor).isLight() ? "black" : "white"
    }}
    id={`color-picker-${cheerleaderInitials}`}
    onClick={(e) => {
      e.stopPropagation();
    }}
    shape="round"
  >
    {cheerleaderInitials}
    <IonPopover
      trigger={`color-picker-${cheerleaderInitials}`}
      backdropDismiss
      dismissOnSelect
      showBackdrop={false}
    >
      <IonContent>
        <BlockPicker
          color={currentColor}
          colors={COLORS}
          onChangeComplete={(color) => onColorChange(color.hex)}
          width="inherit"
        />
      </IonContent>
    </IonPopover>
  </IonButton>
);

export default ColorPicker;
