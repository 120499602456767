import { useContext } from "react";
import { Activity, LineupEntry } from "../api/types/routine.types";
import { addActivity } from "../api/collections/activityCollection";
import { RoutineContext } from "../state/RoutineContext";

const useActivityCollection = () => {
  const { selectedRoutine, activities, lineupEntries, currentActivity } =
    useContext(RoutineContext);

  const createActivity = (activityValues: Omit<Activity, "id">) => {
    if (!selectedRoutine) throw Error("No routine selected");
    return addActivity(selectedRoutine.id, { ...activityValues });
  };

  const findPreviousLineup = (lineupEntryToSearchFrom: LineupEntry) => {
    const prevActivity = activities.find(
      (activity) =>
        currentActivity &&
        activity.positionInRoutine === currentActivity.positionInRoutine - 1
    );
    return lineupEntries.find(
      (lineup) =>
        lineup.cheerId === lineupEntryToSearchFrom.cheerId &&
        prevActivity &&
        lineup.activityId === prevActivity.id
    );
  };

  const findNextLineup = (lineupEntryToSearchFrom: LineupEntry) => {
    const nextActivity = activities.find(
      (activity) =>
        currentActivity &&
        activity.positionInRoutine === currentActivity.positionInRoutine + 1
    );
    return lineupEntries.find(
      (lineup) =>
        lineup.cheerId === lineupEntryToSearchFrom.cheerId &&
        nextActivity &&
        lineup.activityId === nextActivity.id
    );
  };

  const findPreviousAndNextLineupEntryForCheer = (
    lineupEntryToSearchFrom: LineupEntry
  ) => {
    const prevLineup = findPreviousLineup(lineupEntryToSearchFrom);
    const nextLineup = findNextLineup(lineupEntryToSearchFrom);
    return { prevLineup, nextLineup };
  };

  const findHighestPositionInRoutine = () => {
    let highestPostion = 0;
    activities.forEach((activity) => {
      if (activity.positionInRoutine > highestPostion)
        highestPostion = activity.positionInRoutine;
    });
    return highestPostion;
  };

  return {
    findHighestPositionInRoutine,
    createActivity,
    findPreviousAndNextLineupEntryForCheer
  };
};
export default useActivityCollection;
