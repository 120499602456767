export type Feedback = {
  category: FeedbackCategory;
  content: string;
  userId: string;
};

export enum FeedbackCategory {
  "Question" = "Question",
  "Bug Report" = "Bug Report",
  "Billing" = "Billing",
  "User Experience" = "User Experience",
  "Account" = "Account",
  "Feature Request" = "Feature Request",
  "Other" = "Other"
}
