import { IonButton, IonItem, IonText } from "@ionic/react";
import React from "react";

type ModalHeaderProps = {
  headerText?: string;
  cancelFunction: () => void;
};

const ModalHeader: React.FC<ModalHeaderProps> = (props) => (
  <IonItem lines="full">
    {props.headerText && (
      <IonItem lines="none">
        <IonText>
          <h6 style={{ fontWeight: "bolder" }}>
            {props.headerText.toUpperCase()}
          </h6>
        </IonText>
      </IonItem>
    )}
    <IonButton
      size="default"
      color="dark"
      fill="clear"
      slot="end"
      onClick={() => props.cancelFunction()}
    >
      x
    </IonButton>
  </IonItem>
);

export default ModalHeader;
