import { IonLoading, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Redirect, Route } from "react-router";
import { Capacitor } from "@capacitor/core";
import React from "react";
import ScreenLogin from "../../pages/login/ScreenLogin";
import ScreenSignup from "../../pages/login/ScreenSignup";
import ScreenRoutine from "../../pages/routine/ScreenRoutine";
import Menu from "../menu/Menu";
import NavLink from "./NavLinks";

const CMRouter: React.FC = () => {
  const auth = getAuth();
  const [user, loading] = useAuthState(auth);

  if (loading) return <IonLoading isOpen={loading} />;

  if (!user) {
    return (
      <IonRouterOutlet>
        <Route exact path={NavLink.LOGIN} component={ScreenLogin} />
        <Route exact path={NavLink.SIGNUP} component={ScreenSignup} />
        <Redirect to={NavLink.LOGIN} />
      </IonRouterOutlet>
    );
  }

  return (
    <IonSplitPane
      contentId="main"
      when={Capacitor.isNativePlatform() ? "false" : "xl"}
      disabled={false}
      style={{ "--side-width": "25%", "--side-max-width": "25%" }}
    >
      <Menu />
      <IonRouterOutlet id="main">
        <Route exact path={NavLink.ROUTINE} component={ScreenRoutine} />
        <Redirect to={NavLink.ROUTINE} />
      </IonRouterOutlet>
    </IonSplitPane>
  );
};

export default CMRouter;
