import { Carpet } from "./carpet.types";

export type Position = {
  x?: number;
  y?: number;
};

export type LineupEntry = {
  id: string;
  cheerId: string;
  activityId: string;
  path: string;
  stuntDuringActivity?: string;
};

export enum ActivityType {
  LINEUP = "LINEUP",
  TRANSITION = "TRANSITION"
}

export type CommonActivity = {
  id: string;
  type: ActivityType;
  name: string;
  counts: number;
  positionInRoutine: number;
};

export type Lineup = CommonActivity & {
  type: ActivityType.LINEUP;
};

export type Transition = CommonActivity & {
  type: ActivityType.TRANSITION;
};

export type Activity = Lineup | Transition;

export type Routine = {
  id: string;
  teamId: string;
  name: string;
  maxCounts: number;
  carpetType: string | Carpet;
  excludedCheerleader: string[];
};
