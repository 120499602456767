import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { Unsubscribe } from "firebase/firestore";
import { CMUser } from "../api/types/user.types";
import { getUser, subscribeUser } from "../api/collections/userCollection";

type UserContextType = {
  currentUser?: CMUser;
  setCurrentUser: (user: CMUser) => void;
};

export const UserContext = React.createContext<UserContextType>({
  currentUser: undefined,
  setCurrentUser: () => {}
});

const UserContextProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [currentUser, setCurrentUser] = useState<CMUser>();

  const context: UserContextType = useMemo(
    () => ({ currentUser, setCurrentUser }),
    [currentUser]
  );

  const { currentUser: currentAuthUser } = getAuth();

  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined;
    console.log("user going to subscribe: ", currentAuthUser);
    if (currentAuthUser) {
      unsubscribe = subscribeUser(currentAuthUser.uid, (cmUser) => {
        console.log("User updated:", cmUser);
        setCurrentUser(cmUser);
      });
    }
    if (!unsubscribe) setCurrentUser(undefined);
    return () => {
      console.log("unsubscribe user");
      unsubscribe && unsubscribe();
    };
  }, [currentAuthUser]);

  useEffect(() => {
    const auth = getAuth();
    const updateCurrentUser = async (user: User | null) => {
      if (!user) {
        setCurrentUser(undefined);
        return;
      }
      console.log("New user auth state");
      const newCMUser = await getUser(user.uid);
      setCurrentUser(newCMUser);
      console.log(newCMUser);
    };
    const unsubscribe = onAuthStateChanged(auth, updateCurrentUser);
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};

export default UserContextProvider;
