import { Carpet } from "../api/types/carpet.types";

const carpet = new Map<string, Carpet>();
carpet.set("none", { length: 0, width: 0, countOfCarpets: 0 });
carpet.set("european", { length: 10, width: 2, countOfCarpets: 7 });
carpet.set("american", { length: 10, width: 1.8, countOfCarpets: 9 });

export const getCarpetByCarpetType = (matType: string): Carpet => {
  const mat = carpet.get(matType);
  if (!mat) return carpet.get("none")!;
  return mat;
};

export const getTypes = () => {
  const carpetTypes = Array.from(carpet.keys());
  carpetTypes.splice(0, 1);
  return carpetTypes;
};

export default { getCarpetByCarpetType, getTypes };
