import { initializeApp } from "firebase/app";
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  connectAuthEmulator
} from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

import { Capacitor } from "@capacitor/core";

import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const devFirebaseConfig = {
  apiKey: "AIzaSyCwbQrMAd7rlVyy4lt9s2fcR4GvbPOJz04",
  authDomain: "cheermaster-43968.firebaseapp.com",
  projectId: "cheermaster-43968",
  storageBucket: "cheermaster-43968.appspot.com",
  messagingSenderId: "8490942565",
  appId: "1:8490942565:web:4e689c5549e94b0661c68c"
};

const prodFirebaseConfig = {
  apiKey: "AIzaSyCCJL8eaiHOohSv_BKkguIyWIG2BDb20cw",
  authDomain: "cheermaster-prod.firebaseapp.com",
  projectId: "cheermaster-prod",
  storageBucket: "cheermaster-prod.appspot.com",
  messagingSenderId: "221502992144",
  appId: "1:221502992144:web:52e7330e5262a57abb7f95",
  measurementId: "G-JBVQF8PR67"
};

const isDevelopMode = process.env.REACT_APP_STAGE === "develop";
const shouldUseLocalDatabase = process.env.REACT_APP_STAGE === "developlocal";
console.log("Develop Mode? ", isDevelopMode);

const config =
  isDevelopMode || shouldUseLocalDatabase
    ? devFirebaseConfig
    : prodFirebaseConfig;
const app = initializeApp(config);
const auth = Capacitor.isNativePlatform()
  ? initializeAuth(app, {
      persistence: indexedDBLocalPersistence
    })
  : getAuth(app);

shouldUseLocalDatabase && connectAuthEmulator(auth, "http://localhost:9099");

auth.useDeviceLanguage();

export const db = getFirestore(app);
export const functions = getFunctions(app, "europe-west3");
shouldUseLocalDatabase && connectFirestoreEmulator(db, "localhost", 8080);
shouldUseLocalDatabase &&
  connectFunctionsEmulator(functions, "localhost", 5001);

export default app;
