import React, { RefObject } from "react";
import { Position } from "../../api/types/routine.types";

export function getXAndY(e: React.PointerEvent<SVGCircleElement>) {
  const bbox = e.currentTarget.getBoundingClientRect();
  const x = e.clientX - bbox.left;
  const y = e.clientY - bbox.top;
  return { x, y };
}

export function updatePathPointWithXAndY(
  svgRef: RefObject<SVGSVGElement>,
  e: React.PointerEvent<SVGCircleElement>
): DOMPoint {
  const pathPoint = svgRef.current!.createSVGPoint();
  pathPoint.x = e.clientX;
  pathPoint.y = e.clientY;
  return pathPoint.matrixTransform(svgRef.current!.getScreenCTM()?.inverse());
}

export function isInChosenResolution(
  position: Position,
  lastPosition: React.MutableRefObject<Position>
) {
  return (
    Math.abs(position.x! - lastPosition.current.x!) > 10 ||
    Math.abs(position.y! - lastPosition.current.y!) > 10
  );
}
