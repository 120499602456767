import { useCallback, useContext } from "react";
import { Cheerleader } from "../api/types/team.types";
import { TeamContext } from "../state/TeamContext";
import { RoutineContext } from "../state/RoutineContext";
import useLineupEntryCollection from "./useLineupEntryCollection";
import { updateCheerleader } from "../api/collections/cheerleaderCollection";
import { updateRoutine } from "../api/collections/routineCollection";
import { Routine } from "../api/types/routine.types";
import { updateLineupEntry } from "../api/collections/lineupEntryCollection";

const useCheerSettings = () => {
  const {
    selectedTeam,
    cheerleaders,
    setCheerleaders,
    setSelectedCheerleader
  } = useContext(TeamContext);

  const {
    lineupEntriesForCurrentActivity,
    setSelectedRoutine,
    selectedRoutine
  } = useContext(RoutineContext);
  const { createLineupEntry } = useLineupEntryCollection();

  const updateSelectedCheerleader = useCallback(
    (
      cheerleaderToUpdate: Cheerleader,
      fieldsToUpdate: Partial<Cheerleader>
    ) => {
      if (!selectedTeam) return;
      const updatedCheerleader = { ...cheerleaderToUpdate, ...fieldsToUpdate };
      const updatedCheerleaders = cheerleaders.map((cheerleader) => {
        if (cheerleader.id === cheerleaderToUpdate.id)
          return updatedCheerleader;
        return cheerleader;
      });
      setCheerleaders(updatedCheerleaders);
      setSelectedCheerleader(updatedCheerleader);
      updateCheerleader(selectedTeam.id, updatedCheerleader);
    },
    [cheerleaders, selectedTeam, setCheerleaders, setSelectedCheerleader]
  );

  const writeToFirebaseRoutineAndLineupEntries = async (
    updatedRoutine: Routine,
    routineId: string
  ) => {
    setSelectedRoutine(updatedRoutine);
    await updateRoutine(updatedRoutine);
    await updateLineupEntry(routineId, lineupEntriesForCurrentActivity);
  };

  /**
   * Setzt den Sichtbarkeitsstatus eines Cheerleaders
   * @param hidden Der neue Sichtbarkeitsstatus des Cheerleaders
   * @param cheerleader Der Cheerleader, dessen Sichtbarkeitsstatus geändert werden soll.
   */
  const updateCheerVisibility = async (
    cheerleader: Cheerleader,
    hidden: boolean = false
  ) => {
    if (!selectedRoutine) return;
    const updatedHiddenCheers = new Set<string>(
      selectedRoutine?.excludedCheerleader
    );
    if (!hidden) updatedHiddenCheers.add(cheerleader.id);
    else updatedHiddenCheers.delete(cheerleader.id);
    const newCurrentRoutine = {
      ...selectedRoutine,
      excludedCheerleader: Array.from(updatedHiddenCheers)
    } as Routine;
    await writeToFirebaseRoutineAndLineupEntries(
      newCurrentRoutine,
      selectedRoutine?.id
    );
  };

  /**
   * Setzt den Sichtbarkeitsstatus aller Cheerleader.
   * Solange mindestens ein Cheerleader sichtbar ist, werden alle ausgeblendet.
   * Ist kein Cheerleader sichtbar werden alle eingeblendet
   */
  const toggleAllCheersVisibility = async () => {
    if (!selectedRoutine) return;
    const updatedRoutine = { ...selectedRoutine };
    if (selectedRoutine && !selectedRoutine.excludedCheerleader.length) {
      updatedRoutine.excludedCheerleader = cheerleaders.map(
        (cheer) => cheer.id
      );
    } else {
      updatedRoutine.excludedCheerleader = [];
    }
    await writeToFirebaseRoutineAndLineupEntries(
      updatedRoutine,
      selectedRoutine?.id
    );
  };

  const isCheerInCurrentLineUp = (cheerId: string) =>
    lineupEntriesForCurrentActivity.some((entry) => entry.cheerId === cheerId);

  const getFirstPositionOfCheerleader = (positionOfCheerleader: number) => {
    const indexOfCheerleader = positionOfCheerleader - 1;
    const rowOfCheerleader =
      indexOfCheerleader < 5 ? 0 : Math.floor(indexOfCheerleader / 20);
    const cheerleaderY = rowOfCheerleader + 1;
    const cheerleaderX = indexOfCheerleader + 1 - rowOfCheerleader * 20;
    return `M ${50 * cheerleaderX} ${50 * cheerleaderY} L ${
      50 * cheerleaderX
    } ${50 * cheerleaderY}`;
  };

  const createLineupEntriesForFirstActivity = (activityId: string) => {
    let positionOfCheerleader = 1;
    cheerleaders.forEach((cheerleader) => {
      const cheerId = cheerleader.id;
      createLineupEntry({
        cheerId,
        path: getFirstPositionOfCheerleader(positionOfCheerleader),
        activityId
      });
      positionOfCheerleader += 1;
    });
  };

  return {
    updateCheerVisibility,
    toggleAllCheersVisibility,
    isCheerInCurrentLineUp,
    updateSelectedCheerleader,
    createLineupEntriesForFirstActivity
  };
};

export default useCheerSettings;
