import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonLoading,
  IonRow
} from "@ionic/react";
import useAuth from "../../hooks/useAuth";
import NavLink from "../../components/router/NavLinks";
import EmailPasswordForm from "./EmailPasswordForm";

const LoginCard: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { login } = useAuth();

  const handleLogin = async (email: string, password: string) => {
    setLoading(true);
    await login(email, password);
    setLoading(false);
  };

  return (
    <IonCard className="loginCard">
      <IonLoading isOpen={loading} />
      <div className="loginCard">
        <IonCardHeader>
          <IonCardTitle>Login</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <EmailPasswordForm buttonLabel="Login" onButtonClick={handleLogin} />
          <IonRow>
            <IonCol>
              <p style={{ fontSize: "small" }}>
                Don&apos;t have an account?{" "}
                <Link to={NavLink.SIGNUP}>Sign up!</Link>
              </p>
              <p style={{ fontSize: "small" }}>
                By clicking LOGIN you agree to our{" "}
                <a href="https://cheermaster.org/">Policy</a>
              </p>
            </IonCol>
          </IonRow>
        </IonCardContent>
      </div>
    </IonCard>
  );
};

export default LoginCard;
