import {
  IonContent,
  IonLabel,
  IonMenu,
  IonSegment,
  IonSegmentButton,
  SegmentValue
} from "@ionic/react";
import { ReactNode, useContext, useState } from "react";
import CheerleaderList from "./cheerleaderList/CheerleaderList";
import ActivityList from "./activity/ActivityList";
import UserInfo from "./UserInfo";
import { RoutineContext } from "../../state/RoutineContext";
import { TeamContext } from "../../state/TeamContext";

const Menu = () => {
  const { selectedRoutine } = useContext(RoutineContext);
  const { selectedTeam } = useContext(TeamContext);
  const [chosenSegment, setChosenSegment] =
    useState<SegmentValue>("cheerleader");
  const segmentChildren = new Map<string, ReactNode>([
    ["cheerleader", <CheerleaderList />],
    ["activities", <ActivityList />]
  ]);
  const widthOfMenu = {
    "--width": "400px",
    "--side-width": "25%",
    "--side-max-width": "50%"
  };

  return (
    <IonMenu
      className="sidebar-menu"
      contentId="main"
      type="overlay"
      style={widthOfMenu}
    >
      <IonContent>
        <UserInfo />
        {selectedTeam && (
          <>
            <IonSegment
              mode="ios"
              onIonChange={(e) => {
                if (!e.detail.value) return;
                setChosenSegment(e.detail.value);
              }}
              value={chosenSegment}
            >
              <IonSegmentButton mode="ios" value="cheerleader">
                <IonLabel>Team Members</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                mode="ios"
                value="activities"
                disabled={!selectedRoutine}
              >
                <IonLabel>Sections</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            {segmentChildren.get(chosenSegment.toString())}
          </>
        )}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
