import React, { useContext } from "react";
import { IonButton, IonIcon, IonLabel } from "@ionic/react";
import { eye, eyeOff } from "ionicons/icons";
import { SettingsContext } from "../../../state/SettingsContext";

interface StuntShowButtonProps {}

const StuntShowButton: React.FC<StuntShowButtonProps> = () => {
  const { showStunts, setShowStunts } = useContext(SettingsContext);
  return (
    <IonButton
      size="small"
      fill="outline"
      onClick={() => setShowStunts((prevState) => !prevState)}
    >
      <IonIcon slot="end" icon={showStunts ? eye : eyeOff} color="primary" />
      <IonLabel>stunts</IonLabel>
    </IonButton>
  );
};

export default StuntShowButton;
