import React, { useState } from "react";
import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  useIonAlert
} from "@ionic/react";
import { save } from "ionicons/icons";
import classNames from "classnames";

interface UserInfoSubItemProps {
  label: string;
  placeholder: string | undefined;
  emptyMessage: string;
  saveFunction: (value: string) => Promise<void>;
  isEditDisabled: boolean;
  lines?: "none" | "full" | "inset";
  className?: string;
}

const UserInfoSubItem = (props: UserInfoSubItemProps) => {
  const [value, setValue] = useState("");
  const [presentAlert] = useIonAlert();
  return (
    <IonItem
      className={classNames(props.className, "max-height-2")}
      lines={props.lines}
    >
      <IonInput
        label={props.label}
        labelPlacement="fixed"
        placeholder={props.placeholder || props.emptyMessage}
        value={value}
        type="text"
        onIonInput={(e) => {
          if (typeof e.target.value === "string") setValue(e.target.value);
        }}
        readonly={props.isEditDisabled}
      />
      {!props.isEditDisabled &&
        value !== "" &&
        value !== props.placeholder &&
        value !== props.emptyMessage && (
          <IonButton
            fill="clear"
            onClick={async () => {
              if (!value) return;
              await props
                .saveFunction(value)
                .then(() => {
                  setValue("");
                  presentAlert({
                    header: "Save complete",
                    buttons: ["OK"]
                  });
                })
                .catch(() => {
                  setValue("");
                  presentAlert({
                    header: "Save failed",
                    buttons: ["OK"]
                  });
                });
            }}
          >
            <IonIcon icon={save} />
          </IonButton>
        )}
    </IonItem>
  );
};

export default UserInfoSubItem;
