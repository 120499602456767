import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import SignupCard from "./SignupCard";

const ScreenLogin: React.FC = () => (
  <IonPage className="loginPage">
    <IonContent fullscreen>
      <SignupCard />
    </IonContent>
  </IonPage>
);

export default ScreenLogin;
