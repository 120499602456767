import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { trashOutline } from "ionicons/icons";
import { RoutineContext } from "../../../state/RoutineContext";
import ModalHeader from "../ModalHeader";
import { ActivityType } from "../../../api/types/routine.types";

interface EditActivityModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activityIdToUpdate?: string;
  oldName?: string;
  activityCounts?: number;
  activityType?: ActivityType;
}

const EditActivityModal: React.FC<EditActivityModalProps> = (props) => {
  const [newName, setNewName] = useState<string>("");
  const { updateWholeActivity, deleteActivityAndUpdateTheFollowing } =
    useContext(RoutineContext);
  const [newActivityCounts, setNewActivityCounts] = useState(4);

  useEffect(() => {
    if (props.activityCounts === undefined) return;
    setNewActivityCounts(props.activityCounts);
    setNewName(props.oldName ? props.oldName : "");
  }, [props.activityCounts, props.oldName]);

  const closeAndResetModal = () => {
    props.setIsOpen(false);
  };

  const callUpdateActivity = () => {
    if (!props.activityIdToUpdate) return;
    const activityCountToUpdate =
      newActivityCounts === props.activityCounts
        ? undefined
        : newActivityCounts;
    updateWholeActivity(
      props.activityIdToUpdate,
      newName,
      activityCountToUpdate
    );
  };

  return (
    <IonModal
      title="Aktivität bearbeiten"
      isOpen={props.isOpen}
      onIonModalDidDismiss={() => closeAndResetModal()}
      canDismiss
    >
      <IonPage>
        <ModalHeader
          headerText="edit activity"
          cancelFunction={() => props.setIsOpen(false)}
        />
        <IonContent>
          <IonItem lines="none">
            <IonInput
              label="new name: "
              labelPlacement="fixed"
              value={newName}
              maxlength={50}
              onIonChange={(e) => e.detail.value && setNewName(e.detail.value)}
              id="new-activity-name"
            />
          </IonItem>
          <IonItem lines="none">
            <IonInput
              label="beat count:"
              labelPlacement="fixed"
              value={newActivityCounts}
              type="number"
              inputMode="numeric"
              onIonChange={(e) => {
                e.detail.value &&
                  setNewActivityCounts(Number.parseInt(e.detail.value, 10));
              }}
              id="activity-counts"
            />
          </IonItem>
          <IonItem lines="none">
            <IonButton
              onClick={() => {
                callUpdateActivity();
                closeAndResetModal();
              }}
              slot="start"
            >
              save changes
            </IonButton>
            {props.activityType === ActivityType.LINEUP && (
              <IonButton
                fill="outline"
                color="danger"
                slot="end"
                onClick={() => {
                  props.activityIdToUpdate &&
                    deleteActivityAndUpdateTheFollowing(
                      props.activityIdToUpdate
                    );
                  closeAndResetModal();
                }}
                disabled={!props.activityIdToUpdate}
              >
                <IonIcon slot="icon-only" icon={trashOutline} />
                <IonLabel>Delete</IonLabel>
              </IonButton>
            )}
          </IonItem>
        </IonContent>
      </IonPage>
    </IonModal>
  );
};

export default EditActivityModal;
