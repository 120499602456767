import React, { useContext } from "react";
import { IonInput, IonItem } from "@ionic/react";
import { AnimationContext } from "../../../state/AnimationContext";

const PlaySpeedButton: React.FC = () => {
  const { changePlaySpeed, playSpeed } = useContext(AnimationContext);
  return (
    <IonItem lines="none">
      <IonInput
        label="BPM"
        type="number"
        className="flex flex-grow-0"
        maxlength={3}
        max={500}
        min={1}
        step="30"
        onIonBlur={() => {
          if (!(playSpeed > 1 && playSpeed < 500)) changePlaySpeed(100 / 60);
        }}
        clearInput
        value={Math.round(playSpeed * 60)}
        onIonInput={(e) => {
          const chosenBpm = e.target.value as number;
          if (chosenBpm > 1 && chosenBpm < 500)
            changePlaySpeed(Number.parseFloat(`${chosenBpm / 60}`));
        }}
      />
    </IonItem>
  );
};

export default PlaySpeedButton;
