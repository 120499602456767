import React, { useContext, useRef, useState } from "react";
import {
  IonButton,
  IonContent,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  useIonAlert
} from "@ionic/react";
import ModalHeader from "../../../components/menu/ModalHeader";
import { Feedback, FeedbackCategory } from "../../../api/types/feedback.types";
import { UserContext } from "../../../state/UserContext";
import { submitFeedback } from "../../../api/collections/feedbackCollection";

const FeedbackButton: React.FC = () => {
  const modalRef = useRef<HTMLIonModalElement>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [presentAlert] = useIonAlert();
  const { currentUser } = useContext(UserContext);
  const [feedback, setFeedback] = useState<Feedback>({
    category: FeedbackCategory.Other,
    content: "",
    userId: ""
  });
  const feedbackCategories = Object.keys(FeedbackCategory);

  return (
    <>
      <IonButton
        color="secondary"
        expand="block"
        shape="round"
        fill="solid"
        id="open-modal"
        onClick={() => {
          setIsModalVisible(true);
        }}
      >
        Feedback
      </IonButton>
      <IonModal
        isOpen={isModalVisible}
        ref={modalRef}
        trigger="open-modal"
        onWillDismiss={() => setIsModalVisible(false)}
      >
        <IonPage>
          <ModalHeader
            cancelFunction={() => setIsModalVisible(false)}
            headerText="Give us your Feedback"
          />
          <IonContent>
            <IonItem lines="none" className="ion-margin-top">
              <IonLabel position="stacked" style={{ fontWeight: "bold" }}>
                Choose feedback category
              </IonLabel>
              <IonSelect
                aria-label="feedback Category"
                placeholder="Select feedback categoy"
                interface="popover"
                selectedText={feedback.category}
                onIonChange={(e) => {
                  setFeedback((old) => ({
                    ...old,
                    category: e.detail.value
                  }));
                }}
              >
                {feedbackCategories.map((category) => (
                  <IonSelectOption key={category} value={category}>
                    {category}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem lines="none" className="ion-margin-top">
              <IonTextarea
                rows={10}
                className="textAreaBorder"
                placeholder="Enter your Feedback here"
                clearOnEdit
                label="Type in your Feedback"
                labelPlacement="stacked"
                onIonChange={(e) => {
                  setFeedback((old) => ({
                    ...old,
                    content: e.detail.value as string
                  }));
                }}
              />
            </IonItem>
            <IonRow className="ion-justify-content-center ion-margin-top">
              <IonButton
                color="secondary"
                expand="block"
                shape="round"
                fill="solid"
                onClick={async () => {
                  const response = await submitFeedback({
                    ...feedback,
                    userId: currentUser!.id
                  });
                  setIsModalVisible(false);
                  if (response.data.response) {
                    await presentAlert({
                      header: "THX",
                      message: "Thank you for your Feedback",
                      buttons: ["OK"]
                    });
                  }
                  if (response.data.error)
                    await presentAlert({
                      header: "ERROR",
                      message:
                        "Something went wrong with your feedback, please try again",
                      buttons: ["OK"]
                    });
                }}
              >
                Submit
              </IonButton>
            </IonRow>
          </IonContent>
        </IonPage>
      </IonModal>
    </>
  );
};

export default FeedbackButton;
