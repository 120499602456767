import {
  addDoc,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  query,
  where,
  writeBatch
} from "firebase/firestore";
import CollectionName from "../types/collections.types";
import { createCollection, subscribeCollection } from "./collectionService";
import { LineupEntry } from "../types/routine.types";

const getLineupEntrySubcollection = (routineId: string) =>
  createCollection<LineupEntry>(
    `${CollectionName.ROUTINES}/${routineId}/${CollectionName.LINEUP_ENTRIES}`
  );

export const subscribeLineupEntries = (
  routineId: string,
  onNewSnapshot: (lineupEntries: LineupEntry[]) => void
) => {
  if (!routineId) throw Error("No routineId provided for query");
  const lineupEntryCollection = getLineupEntrySubcollection(routineId);
  const lineupEntryQuery = query(lineupEntryCollection);
  return subscribeCollection<LineupEntry>(lineupEntryQuery, onNewSnapshot);
};

export const addLineupEntry = async (
  routineId: string,
  lineupEntry: Omit<LineupEntry, "id">
) => {
  const lineupEntryCollection = getLineupEntrySubcollection(routineId);
  return addDoc(lineupEntryCollection, lineupEntry);
};

export const removeLineupEntry = async (
  routineId: string,
  lineupEntryId: string
) => {
  const lineupEntryCollection = getLineupEntrySubcollection(routineId);
  const lineupEntryToRemove = doc(lineupEntryCollection, lineupEntryId);
  return deleteDoc(lineupEntryToRemove);
};

export const updateLineupEntry = async (
  routineId: string,
  updatedLineupEntries: Array<LineupEntry>
) => {
  const batch = writeBatch(getFirestore());
  const lineupEntryCollection = getLineupEntrySubcollection(routineId);
  updatedLineupEntries.forEach((lineupEntry) => {
    const lineupRef = doc(lineupEntryCollection, lineupEntry.id);
    batch.update(lineupRef, { ...lineupEntry });
  });
  await batch.commit();
};

export const deleteLineupEntriesByRoutineIdAndCheerleaderId = async (
  routineId: string,
  cheerleaderId: string
) => {
  const batch = writeBatch(getFirestore());
  const lineupEntrySubcollection = getLineupEntrySubcollection(routineId);
  const q = query(
    lineupEntrySubcollection,
    where("cheerId", "==", cheerleaderId)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((result) => {
    const lineupRef = doc(lineupEntrySubcollection, result.id);
    batch.delete(lineupRef);
  });
  await batch.commit();
};
