import { ToastOptions } from "@ionic/react";
import { HookOverlayOptions } from "@ionic/react/dist/types/hooks/HookOverlayOptions";

const defaultToastOptions: ToastOptions & HookOverlayOptions = {
  duration: 3000
};

export const errorToast = (
  message: string
): ToastOptions & HookOverlayOptions => ({
  ...defaultToastOptions,
  message,
  color: "danger"
});

export const successToast = (
  message: string
): ToastOptions & HookOverlayOptions => ({
  ...defaultToastOptions,
  message,
  color: "success"
});
