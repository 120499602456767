import { useContext } from "react";
import { DocumentReference } from "firebase/firestore";
import { Activity, LineupEntry } from "../api/types/routine.types";
import { RoutineContext } from "../state/RoutineContext";
import {
  addLineupEntry,
  removeLineupEntry,
  updateLineupEntry
} from "../api/collections/lineupEntryCollection";
import { getCoordinateAtEndOfPath } from "../util/pathUtilities";

const useLineupEntryCollection = () => {
  const { selectedRoutine } = useContext(RoutineContext);

  const createLineupEntry = (
    lineupEntryValues: Omit<LineupEntry, "id">,
    routineId?: string
  ) => {
    if (!selectedRoutine && !routineId)
      throw Error("No to create Lineup Entry");
    return addLineupEntry(routineId || selectedRoutine!.id, {
      ...lineupEntryValues
    });
  };

  const deleteLineUpEntry = (routineId: string, lineupId: string) =>
    removeLineupEntry(routineId, lineupId);

  const updateLineUp = (
    lineUp: LineupEntry,
    fieldsToUpdate: Partial<LineupEntry>
  ) => {
    if (!selectedRoutine) throw Error("No routine selected");
    return updateLineupEntry(selectedRoutine.id, [
      {
        ...lineUp,
        ...fieldsToUpdate
      }
    ]);
  };

  const createLineupEntriesForNewActivityByOldEndPoint = (
    lineupEntriesForCurrentActivity: LineupEntry[],
    activityDocRef: DocumentReference<Omit<Activity, "id">>
  ) => {
    lineupEntriesForCurrentActivity.forEach((lineUp) => {
      const coordinate = getCoordinateAtEndOfPath(lineUp.path);
      createLineupEntry({
        path: `M ${coordinate?.x} ${coordinate?.y} L ${coordinate?.x} ${coordinate?.y}`,
        activityId: activityDocRef.id,
        cheerId: lineUp.cheerId
      });
    });
  };

  return {
    createLineupEntry,
    deleteLineUpEntry,
    updateLineUp,
    createLineupEntriesForNewActivityByOldEndPoint
  };
};
export default useLineupEntryCollection;
