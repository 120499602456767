import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow
} from "@ionic/react";
import React, { useContext, useState } from "react";
import { chevronForwardOutline } from "ionicons/icons";
import { RoutineContext } from "../../../state/RoutineContext";
import ActivityIcon from "./ActivityIcon";
import { Activity, ActivityType } from "../../../api/types/routine.types";
import { SettingsContext } from "../../../state/SettingsContext";
import { AnimationContext } from "../../../state/AnimationContext";
import EditActivityModal from "./EditActivityModal";

interface ActivityItemProps {
  activity: Activity;
  eightOfCount: (numberOfCounts: number) => string;
}

const ActivityItem: React.FC<ActivityItemProps> = (props) => {
  const {
    selectedRoutine,
    currentActivity,
    saveCurrentLineupEntriesAndChangeCurrentActivity,
    calculateStartTimeForGivenActivity
  } = useContext(RoutineContext);
  const { setIsPlayAllRoutineActive } = useContext(SettingsContext);
  const { resetAnimationsToStart } = useContext(AnimationContext);

  const [isEditActivityVisible, setIsEditActivityVisible] = useState(false);
  const lineup = props.activity.type === ActivityType.LINEUP;
  const icon = (
    <IonCol
      size="auto"
      className="ion-align-items-center"
      style={{ display: "flex" }}
    >
      <ActivityIcon activityType={props.activity.type} />
    </IonCol>
  );
  const justify = `ion-justify-content-${
    props.activity.type === ActivityType.LINEUP ? "start" : "end"
  }`;

  return (
    <IonItem
      className={currentActivity?.id === props.activity.id ? "selected" : ""}
      routerLink="Routine"
      routerDirection="none"
      lines="none"
      mode="md"
      onClick={() => {
        setIsPlayAllRoutineActive(false);
        saveCurrentLineupEntriesAndChangeCurrentActivity(props.activity);
        resetAnimationsToStart();
      }}
    >
      <IonGrid
        style={{
          background:
            props.activity.type === ActivityType.TRANSITION ? "oldlace" : ""
        }}
      >
        <IonRow class="ion-align-items-center">
          {props.activity.type === ActivityType.LINEUP && icon}
          <IonCol>
            <IonRow
              class={justify}
              style={{ fontSize: lineup ? "" : "0.6rem" }}
            >
              {props.activity.name}
            </IonRow>
            <IonRow class={justify}>
              <small style={{ fontSize: lineup ? "" : "0.6rem" }}>{`${
                calculateStartTimeForGivenActivity(props.activity) -
                (props.activity.counts ? 0 : 1) // Wenn die Transition 0 lang ist, muss hier der Wert angepasst werden
              }-${
                calculateStartTimeForGivenActivity(props.activity) +
                props.activity.counts -
                1
              } | ${Math.ceil(
                parseFloat(
                  props.eightOfCount(
                    calculateStartTimeForGivenActivity(props.activity) +
                      calculateStartTimeForGivenActivity(props.activity) -
                      1
                  )
                )
              )} of ${
                selectedRoutine &&
                props.eightOfCount(selectedRoutine?.maxCounts)
              }`}</small>
            </IonRow>
          </IonCol>
          {props.activity.type === ActivityType.TRANSITION && icon}
          <IonCol size="auto" class="ion-justify-content-end">
            <IonRow class="ion-justify-content-end">
              <IonButton
                fill="clear"
                onClick={() => setIsEditActivityVisible(true)}
              >
                <IonIcon icon={chevronForwardOutline} />
              </IonButton>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
      <EditActivityModal
        key={`Modal${props.activity.id}`}
        isOpen={isEditActivityVisible}
        setIsOpen={setIsEditActivityVisible}
        activityIdToUpdate={currentActivity?.id}
        activityCounts={currentActivity?.counts}
        oldName={currentActivity?.name}
        activityType={currentActivity?.type}
      />
    </IonItem>
  );
};

export default ActivityItem;
