import React, { useCallback } from "react";
import { Activity, LineupEntry, Routine } from "../api/types/routine.types";
import { updateLineupEntry } from "../api/collections/lineupEntryCollection";
import {
  removeActivity,
  updateActivity,
  updateActivityAsBatch
} from "../api/collections/activityCollection";

interface UseCurrentRoutineHandlerProps {
  setLineupEntriesForCurrentActivity: React.Dispatch<
    React.SetStateAction<LineupEntry[]>
  >;
  changedCurrentLineupEntries: LineupEntry[];
  setChangedCurrentLineupEntries: React.Dispatch<
    React.SetStateAction<LineupEntry[]>
  >;
  selectedRoutine: Routine | undefined;
  currentActivity: Activity | undefined;
  setCurrentActivity: React.Dispatch<
    React.SetStateAction<Activity | undefined>
  >;
  activities: Activity[];
  setActivities: React.Dispatch<React.SetStateAction<Activity[]>>;
}

const useCurrentRoutineHandler = (props: UseCurrentRoutineHandlerProps) => {
  const {
    setLineupEntriesForCurrentActivity,
    setChangedCurrentLineupEntries,
    currentActivity,
    setCurrentActivity,
    selectedRoutine,
    changedCurrentLineupEntries,
    activities
  } = props;

  const updateLineupEntryInCurrentActivity = (
    lineupEntryToUpdate: LineupEntry
  ) => {
    if (!lineupEntryToUpdate.id) return;
    setLineupEntriesForCurrentActivity((prevState) =>
      prevState.map((lineupEntry) => {
        if (lineupEntry.id === lineupEntryToUpdate.id)
          return lineupEntryToUpdate;
        return lineupEntry;
      })
    );
    setChangedCurrentLineupEntries((prevState) => {
      if (
        !prevState.find(
          (lineupEntry) => lineupEntry.id === lineupEntryToUpdate.id
        )
      ) {
        return [...prevState, lineupEntryToUpdate];
      }
      return prevState.map((lineupEntry) => {
        if (lineupEntry.id === lineupEntryToUpdate.id)
          return lineupEntryToUpdate;
        return lineupEntry;
      });
    });
  };

  const saveCurrentLineUpEntries = useCallback(() => {
    if (selectedRoutine)
      updateLineupEntry(selectedRoutine.id, changedCurrentLineupEntries);
    setChangedCurrentLineupEntries([]);
  }, [
    changedCurrentLineupEntries,
    selectedRoutine,
    setChangedCurrentLineupEntries
  ]);

  const saveCurrentLineupEntriesAndChangeCurrentActivity = useCallback(
    (newActivity?: Activity) => {
      saveCurrentLineUpEntries();
      setCurrentActivity(newActivity);
    },
    [saveCurrentLineUpEntries, setCurrentActivity]
  );

  const updateWholeActivity = useCallback(
    async (
      activityIdToUpdate: string,
      newActivityName?: string,
      newActivityCount?: number
    ) => {
      let activityToUpdate = activities.find(
        (activity) => activity.id === activityIdToUpdate
      );
      if (!selectedRoutine) return;
      if (newActivityName && activityToUpdate)
        activityToUpdate = { ...activityToUpdate, name: newActivityName };
      if (newActivityCount !== undefined && activityToUpdate) {
        activityToUpdate = {
          ...activityToUpdate,
          counts: newActivityCount
        } as Activity;
      }
      if (!activityToUpdate) return;
      updateActivity(
        selectedRoutine?.id,
        activityToUpdate.id,
        activityToUpdate
      );
    },
    [activities, selectedRoutine]
  );

  const findAndDeleteActivity = useCallback(
    async (activityIdToDelete: string) => {
      const activityToDelete = activities.find(
        (activity) => activity.id === activityIdToDelete
      );
      if (!activityToDelete || !props.selectedRoutine) return undefined;
      await removeActivity(props.selectedRoutine.id, activityToDelete.id);
      return activityToDelete;
    },
    [activities, props.selectedRoutine]
  );

  const updateActivityPositionInRoutine = useCallback(
    (postionToUpdateFrom: number, positionsToDowngrade: number) =>
      activities
        .filter((activity) => activity.positionInRoutine > postionToUpdateFrom)
        .map((filteredActivities) => ({
          ...filteredActivities,
          positionInRoutine:
            filteredActivities.positionInRoutine - positionsToDowngrade
        })),
    [activities]
  );

  const deleteActivityAndUpdateTheFollowing = useCallback(
    async (activityIdToDelete: string) => {
      const deletedActivity = await findAndDeleteActivity(activityIdToDelete);
      if (!deletedActivity) return;
      const lineupToDelete = activities.find(
        (activity) =>
          activity.positionInRoutine + 1 === deletedActivity.positionInRoutine
      )?.id;
      if (lineupToDelete) await findAndDeleteActivity(lineupToDelete);
      const followingActivitiesUpdated = updateActivityPositionInRoutine(
        deletedActivity.positionInRoutine,
        lineupToDelete ? 2 : 1
      );
      if (!activities.length) setCurrentActivity(undefined);
      if (followingActivitiesUpdated.length)
        setCurrentActivity(followingActivitiesUpdated[0]);
      else setCurrentActivity(activities[activities.length - 1]);
      if (!followingActivitiesUpdated.length || !props.selectedRoutine) return;
      await updateActivityAsBatch(
        props.selectedRoutine.id,
        followingActivitiesUpdated
      );
    },
    [
      activities,
      findAndDeleteActivity,
      props.selectedRoutine,
      setCurrentActivity,
      updateActivityPositionInRoutine
    ]
  );

  const changeToNextActivity = useCallback(
    (activityId: string) => {
      if (activityId !== currentActivity?.id) return false;
      const nextActivity = activities.find(
        (activity) =>
          currentActivity.positionInRoutine + 1 === activity.positionInRoutine
      );
      if (nextActivity) {
        setCurrentActivity(nextActivity);
        return true;
      }
      return false;
    },
    [activities, currentActivity, setCurrentActivity]
  );

  return {
    updateLineupEntryInCurrentActivity,
    saveCurrentLineUpEntries,
    saveCurrentLineupEntriesAndChangeCurrentActivity,
    updateWholeActivity,
    changeToNextActivity,
    deleteActivityAndUpdateTheFollowing
  };
};

export default useCurrentRoutineHandler;
