import React, { useMemo, useRef, useState } from "react";
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonRow
} from "@ionic/react";
import { personCircle } from "ionicons/icons";

type EmailPasswordFormProps = {
  buttonLabel: string;
  onButtonClick: (
    email: string,
    password: string,
    name?: string,
    hasAcceptedProductChange?: boolean
  ) => void;
  register?: boolean;
};

const EmailPasswordForm: React.FC<EmailPasswordFormProps> = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isBetaAgreed, setIsBetaAgreed] = useState(false);
  const [arePoliciesAccepted, setArePoliciesAccepted] = useState(false);
  const name = useRef<string>("");
  const areAgreementsAccepted = useMemo(
    () => isBetaAgreed && arePoliciesAccepted,
    [isBetaAgreed, arePoliciesAccepted]
  );

  return (
    <>
      <IonRow>
        <IonCol>
          <IonIcon
            style={{ fontSize: "70px", color: "#FF7B54" }}
            icon={personCircle}
          />
        </IonCol>
      </IonRow>
      {props.register && (
        <IonRow>
          <IonCol>
            <IonItem>
              <IonInput
                label="Name"
                labelPlacement="floating"
                type="text"
                onIonChange={(e) => {
                  if (typeof e.detail.value === "string")
                    name.current = e.detail.value;
                }}
              />
            </IonItem>
          </IonCol>
        </IonRow>
      )}
      <IonRow>
        <IonCol>
          <IonItem>
            <IonInput
              type="email"
              labelPlacement="floating"
              label="Email"
              value={email}
              onIonChange={(e) => setEmail(e.detail.value!)}
            />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem>
            <IonInput
              type="password"
              label="Password"
              labelPlacement="floating"
              value={password}
              onIonChange={(e) => setPassword(e.detail.value!)}
            />
          </IonItem>
        </IonCol>
      </IonRow>
      {props.register && (
        <IonRow>
          <IonCol>
            <IonItem>
              <IonCheckbox
                slot="start"
                value={isBetaAgreed}
                labelPlacement="end"
                onIonChange={() => {
                  setIsBetaAgreed((prevState) => !prevState);
                }}
              >
                <p>
                  I agree, that cheermaster is in beta state
                  <br />
                  and features may change
                  <br />
                  and data could be lost
                </p>
              </IonCheckbox>
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonCheckbox
                slot="start"
                value={arePoliciesAccepted}
                labelPlacement="end"
                onIonChange={() => {
                  setArePoliciesAccepted((prevState) => !prevState);
                }}
              >
                <p>
                  I agree to the policies of the cheermaster
                  <br /> AGB
                </p>
              </IonCheckbox>
            </IonItem>
          </IonCol>
        </IonRow>
      )}
      <IonRow>
        <IonCol>
          <IonButton
            expand="block"
            onClick={() =>
              props.onButtonClick(
                email,
                password,
                name.current,
                areAgreementsAccepted
              )
            }
          >
            {props.buttonLabel}
          </IonButton>
        </IonCol>
      </IonRow>
    </>
  );
};

export default EmailPasswordForm;
