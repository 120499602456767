import {
  addDoc,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  updateDoc,
  writeBatch
} from "firebase/firestore";
import { Activity } from "../types/routine.types";
import CollectionName from "../types/collections.types";
import { createCollection, subscribeCollection } from "./collectionService";

const getActivitySubcollection = (routineId: string) =>
  createCollection<Activity>(
    `${CollectionName.ROUTINES}/${routineId}/${CollectionName.ACTIVITIES}`
  );

export const subscribeActivities = (
  routineId: string,
  onNewSnapshot: (activities: Activity[]) => void
) => {
  if (!routineId) throw Error("No routineId provided for query");
  const activityCollection = getActivitySubcollection(routineId);
  const activityQuery = query(
    activityCollection,
    orderBy("positionInRoutine", "asc")
  );
  return subscribeCollection<Activity>(activityQuery, onNewSnapshot);
};

export const addActivity = async (
  routineId: string,
  activity: Omit<Activity, "id">
) => {
  const activityCollection = getActivitySubcollection(routineId);
  console.log(activityCollection, "routineId");
  return addDoc(activityCollection, activity);
};

export const removeActivity = async (routineId: string, activityId: string) => {
  const activityCollection = getActivitySubcollection(routineId);
  const activityToRemove = doc(activityCollection, activityId);
  return deleteDoc(activityToRemove);
};

export const updateActivity = async (
  routineId: string,
  activityId: string,
  updatedActivity: Partial<Activity>
) => {
  const activityCollection = getActivitySubcollection(routineId);
  const activityToUpdate = doc(activityCollection, activityId);
  return updateDoc(activityToUpdate, updatedActivity);
};

export const updateActivityAsBatch = async (
  routineId: string,
  activitiesToUpdate: Array<Activity>
) => {
  const batch = writeBatch(getFirestore());
  const activityCollection = getActivitySubcollection(routineId);
  activitiesToUpdate.forEach((activity) => {
    const activityRef = doc(activityCollection, activity.id);
    batch.update(activityRef, { ...activity });
  });
  await batch.commit();
};

export const getActivitiesByRoutineId = async (routineId: string) => {
  const docRef = getActivitySubcollection(routineId);
  const docSnap = await getDocs(docRef);
  const activities: Array<Activity> = [];
  docSnap.forEach((result) => {
    const newActivity: Activity = result.data();
    newActivity.id = result.id;
    activities.push(newActivity);
  });
  return activities;
};
