import { gsap } from "gsap";
import React from "react";

interface UsePathAnimationProps {
  durationInSec: number | undefined;
  pathRef: React.RefObject<SVGPathElement>;
  targetRef: React.RefObject<SVGElement>;
}

const pathAnimation = (
  props: UsePathAnimationProps,
  onComplete: () => void
) => {
  if (!props.pathRef.current || !props.targetRef.current) return undefined;
  const motion = {
    motionPath: {
      path: props.pathRef.current,
      align: props.pathRef.current,
      autoRotate: false,
      alignOrigin: [0.5, 0.5]
    }
  };

  return gsap.to(props.targetRef.current, {
    duration: props.durationInSec === 0 ? 0.0001 : props.durationInSec,
    paused: true,
    ease: "none",
    ...motion,
    onComplete
  });
};

export default pathAnimation;
