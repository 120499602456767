import { IonIcon } from "@ionic/react";
import {
  accessibilityOutline,
  accessibilitySharp,
  exitOutline,
  exitSharp
} from "ionicons/icons";
import React from "react";
import { ActivityType } from "../../../api/types/routine.types";

type ActivityIconProps = {
  activityType: ActivityType;
};

function ActivityIcon({ activityType }: ActivityIconProps) {
  const LineupIcon = (
    <IonIcon
      ios={accessibilityOutline}
      md={accessibilitySharp}
      color="primary"
    />
  );

  const TransitionIcon = (
    <IonIcon ios={exitOutline} md={exitSharp} color="secondary" />
  );

  if (activityType === ActivityType.LINEUP) return LineupIcon;
  return TransitionIcon;
}

export default ActivityIcon;
