import { Unsubscribe } from "firebase/firestore";
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { subscribeTeams } from "../api/collections/teamCollection";
import { Cheerleader, Group, Team } from "../api/types/team.types";
import { UserContext } from "./UserContext";
import { subscribeGroup } from "../api/collections/groupCollection";
import { subscribeCheerleaders } from "../api/collections/cheerleaderCollection";

interface TeamContextType {
  selectedTeam?: Team;
  setSelectedTeam: (team: Team) => void;
  allTeams?: Team[];
  setAllTeams: (teams: Team[]) => void;
  cheerleaders: Cheerleader[];
  setCheerleaders: (cheerleaders: Cheerleader[]) => void;
  selectedCheerleader?: Cheerleader;
  availableGroups: Group[];
  setAvailableGroups: (groups: Group[]) => void;
  setSelectedCheerleader: (cheerleader?: Cheerleader) => void;
  resetAllTeamStates: () => void;
}

export const TeamContext = React.createContext<TeamContextType>({
  selectedTeam: undefined,
  setSelectedTeam: () => {},
  allTeams: [],
  setAllTeams: () => {},
  cheerleaders: [],
  setCheerleaders: () => {},
  availableGroups: [],
  setAvailableGroups: () => {},
  selectedCheerleader: undefined,
  setSelectedCheerleader: () => {},
  resetAllTeamStates: () => {}
});

const TeamContextProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [availableGroups, setAvailableGroups] = useState<Group[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [allTeams, setAllTeams] = useState<Team[]>([]);
  const [cheerleaders, setCheerleaders] = useState<Cheerleader[]>([]);
  const [selectedCheerleader, setSelectedCheerleader] = useState<Cheerleader>();

  const { currentUser } = useContext(UserContext);
  const resetAllTeamStates = () => {
    setSelectedTeam(undefined);
    setCheerleaders([]);
    setSelectedCheerleader(undefined);
  };

  const context: TeamContextType = useMemo(
    () => ({
      selectedTeam,
      setSelectedTeam,
      allTeams,
      setAllTeams,
      cheerleaders,
      setCheerleaders,
      availableGroups,
      setAvailableGroups,
      selectedCheerleader,
      setSelectedCheerleader,
      resetAllTeamStates
    }),
    [selectedTeam, allTeams, cheerleaders, availableGroups, selectedCheerleader]
  );

  const teamIds = useMemo(
    () => (currentUser && currentUser.teamIds ? currentUser.teamIds : []),
    [currentUser]
  );

  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined;
    if (teamIds.length) {
      unsubscribe = subscribeTeams(teamIds, (teams) => {
        console.log("Teams updated", teams);
        setAllTeams(teams);
        if (teams.length === 1) setSelectedTeam(teams[0]);
        else
          setSelectedTeam((prev) => teams.find((team) => team.id === prev?.id));
      });
    }
    if (!unsubscribe) setAllTeams([]);
    return () => {
      console.log("unsubscribe Teams");
      unsubscribe && unsubscribe();
    };
  }, [teamIds]);

  useEffect(() => {
    let unsubscribeCheers: Unsubscribe;
    let unsubscribeGroups: Unsubscribe;

    if (selectedTeam) {
      unsubscribeCheers = subscribeCheerleaders(selectedTeam.id, (cheers) => {
        const orderedCheerleader = cheers.sort((firstCheer, secondCheer) =>
          firstCheer.name.toUpperCase() > secondCheer.name.toUpperCase()
            ? 1
            : -1
        );
        console.log("New Cheers arriving", orderedCheerleader);
        setCheerleaders(orderedCheerleader);
      });
      unsubscribeGroups = subscribeGroup(selectedTeam.id, setAvailableGroups);
    }

    return () => {
      unsubscribeCheers && unsubscribeCheers();
      unsubscribeGroups && unsubscribeGroups();
    };
  }, [selectedTeam]);

  return (
    <TeamContext.Provider value={context}>{children}</TeamContext.Provider>
  );
};

export default TeamContextProvider;
