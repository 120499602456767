import { useContext } from "react";
import { addTeam, removeTeam } from "../api/collections/teamCollection";
import { Team } from "../api/types/team.types";
import { TeamContext } from "../state/TeamContext";
import { UserContext } from "../state/UserContext";
import { RoutineContext } from "../state/RoutineContext";

const useTeamCollection = () => {
  const { resetAllTeamStates } = useContext(TeamContext);
  const { resetAllRoutineStates } = useContext(RoutineContext);
  const { currentUser } = useContext(UserContext);
  const createTeam = async (teamValues: Partial<Team>) => {
    if (!currentUser) return;
    await addTeam(teamValues, currentUser.id);
    /** TODO: durch Cloud Function erledigen, damit der Vorgang abgeschlossen wird, egal ob der User die Seite verlässt o.ä. */
  };

  const deleteTeam = async (teamId: string) => {
    if (!currentUser) return;
    await removeTeam(teamId, currentUser.id);
    resetAllRoutineStates();
    resetAllTeamStates();
  };

  return { createTeam, deleteTeam };
};
export default useTeamCollection;
