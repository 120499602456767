import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { IonItem, IonRange, IonText } from "@ionic/react";
import { RangeChangeEventDetail } from "@ionic/core/components";
import useRoutineHandler from "../../hooks/useRoutineHandler";
import { AnimationContext } from "../../state/AnimationContext";
import { SettingsContext } from "../../state/SettingsContext";
import { RoutineContext } from "../../state/RoutineContext";

const RoutineProgressBar: React.FC = () => {
  const [sliderInUse, setSliderInUse] = useState(false);
  const { handleCountChange } = useRoutineHandler();
  const { resetAnimationsToStart, pauseAnimations, currentTime } =
    useContext(AnimationContext);
  const {
    currentActivity,
    selectedRoutine,
    usedActivityCounts,
    startTimeForCurrentActivity
  } = useContext(RoutineContext);
  const { setIsPlayAllRoutineActive } = useContext(SettingsContext);
  const ref = useRef<HTMLIonRangeElement>(null);
  const percentageAlreadySet = useMemo(() => {
    if (!selectedRoutine || !selectedRoutine.maxCounts) return 0;
    return (usedActivityCounts * 100) / selectedRoutine.maxCounts;
  }, [selectedRoutine, usedActivityCounts]);

  const onCountChange = (e: CustomEvent<RangeChangeEventDetail>) => {
    const newCount = e.detail.value as number;
    if (sliderInUse && newCount <= usedActivityCounts) {
      setIsPlayAllRoutineActive(false);
      pauseAnimations();
      resetAnimationsToStart();
      handleCountChange(newCount);
    }
  };

  const currentTimes = useMemo(
    () =>
      currentActivity && currentTime < currentActivity.counts
        ? currentTime + startTimeForCurrentActivity
        : startTimeForCurrentActivity,
    [currentActivity, currentTime, startTimeForCurrentActivity]
  );

  useEffect(() => {
    if (!selectedRoutine || !ref.current) return;
    const doc = document.querySelector("#timeSlider");
    const shadow = doc && doc.shadowRoot;
    const firstShadowRootChild =
      shadow && shadow.querySelector(".range-slider");
    if (firstShadowRootChild !== null) {
      // @ts-ignore
      firstShadowRootChild.style.background = `linear-gradient(to right,#e0bb62 0%
      ${percentageAlreadySet}%,#FFD56F ${percentageAlreadySet}% ${100}%)`;
      // @ts-ignore
      firstShadowRootChild.style.borderRadius = "10px";
    }
  }, [percentageAlreadySet, selectedRoutine]);

  return (
    <IonItem>
      <IonText
        slot="start"
        className="circleAroundTextSlider ion-text-center ion-align-items-center"
        style={{
          marginRight: "0px"
        }}
      >
        <b>{currentTimes.toString(10).split(".")[0]}</b>
      </IonText>
      <IonRange
        id="timeSlider"
        ref={ref}
        value={currentTimes}
        min={selectedRoutine ? 1 : 0}
        max={selectedRoutine ? selectedRoutine.maxCounts : 1}
        step={1}
        ticks
        color="primary"
        onIonChange={onCountChange}
        onIonKnobMoveStart={() => setSliderInUse(true)}
        onIonKnobMoveEnd={() => setSliderInUse(false)}
      />
    </IonItem>
  );
};

export default RoutineProgressBar;
