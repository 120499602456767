import React from "react";
import { IonIcon, IonItem } from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import useShowDeleteDialog from "../dialogs/useShowDeleteDialog";
import { Routine } from "../../api/types/routine.types";

interface ItemListWithFunctionAndDeleteProps<T> {
  items: T[];
  setChosenItem: (item: T) => void;
  togglePopOver: () => void;
  deleteFunction: (id: string) => void;
  idOfCurrentChosenItem?: string;
}

const ItemsWithFunctionAndDelete = <T extends Pick<Routine, "id" | "name">>(
  props: ItemListWithFunctionAndDeleteProps<T>
) => {
  const { openDeleteDialog } = useShowDeleteDialog();

  const isItemActive = (item: T) => props.idOfCurrentChosenItem === item.id;

  return (
    <>
      {props.items.map((item) => (
        <IonItem
          key={`${item.id}`}
          style={{
            fontWeight: `${isItemActive(item) ? "bold" : ""}`
          }}
          onClick={() => {
            props.setChosenItem(item);
            props.togglePopOver();
          }}
        >
          {item.name}
          {isItemActive(item) && (
            <IonIcon
              onClick={async () => {
                await openDeleteDialog(item.name, () =>
                  props.deleteFunction(item.id)
                );
              }}
              slot="end"
              icon={trashOutline}
            />
          )}
        </IonItem>
      ))}
    </>
  );
};

export default ItemsWithFunctionAndDelete;
