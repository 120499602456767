import { useIonAlert } from "@ionic/react";

const useShowDeleteDialog = () => {
  const [presentAlert] = useIonAlert();
  const openDeleteDialog = async (
    itemToDeleteName: string,
    deleteFunction: () => void
  ) => {
    await presentAlert({
      header: `delete ${itemToDeleteName}?`,
      buttons: [
        {
          text: "cancel",
          role: "cancel",
          handler: () => undefined
        },
        {
          text: "confirm",
          role: "confirm",
          handler: () => {
            deleteFunction();
          }
        }
      ]
    });
  };

  return { openDeleteDialog };
};

export default useShowDeleteDialog;
