import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import {
  IonButton,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonList,
  IonModal,
  IonPage,
  IonRow
} from "@ionic/react";
import { TeamContext } from "../../state/TeamContext";
import ItemsWithFunctionAndDelete from "../../components/lists/ItemsWithFunctionAndDelete";
import useTeamCollection from "../../hooks/useTeamCollection";
import keyPressListener from "../../util/hotKey";
import ModalHeader from "../../components/menu/ModalHeader";

type TeamCreationFormProps = {
  buttonLabel: string;
  onTeamCreationButtonClick: (name: string) => void;
  togglePopOver: () => void;
};

const TeamCreationForm: React.FC<TeamCreationFormProps> = (props) => {
  const { allTeams, setSelectedTeam, selectedTeam } = useContext(TeamContext);
  const refPopover = useRef<HTMLIonModalElement>(null);
  const { deleteTeam } = useTeamCollection();
  const [isCreationPopUpVisible, setCreationPopUpVisible] =
    useState<boolean>(false);
  const teamName = useRef<string>("");

  const onCreate = useCallback(() => {
    if (teamName.current.length) {
      props.onTeamCreationButtonClick(teamName.current);
      setCreationPopUpVisible(false);
      props.togglePopOver();
    }
  }, [props]);

  useLayoutEffect(() => {
    if (!isCreationPopUpVisible) return () => {};
    const onEnterPress = keyPressListener(
      "Enter",
      onCreate,
      refPopover.current
    );
    return () => {
      onEnterPress();
    };
  }, [isCreationPopUpVisible, onCreate]);

  return (
    <>
      <IonList>
        {allTeams && (
          <ItemsWithFunctionAndDelete
            items={allTeams}
            setChosenItem={setSelectedTeam}
            togglePopOver={props.togglePopOver}
            deleteFunction={deleteTeam}
            idOfCurrentChosenItem={selectedTeam?.id}
          />
        )}
        <IonItem>
          <IonButton
            color="secondary"
            expand="block"
            shape="round"
            fill="solid"
            onClick={() => {
              setCreationPopUpVisible(true);
            }}
          >
            {props.buttonLabel}
          </IonButton>
        </IonItem>
      </IonList>
      <IonModal
        isOpen={isCreationPopUpVisible}
        onDidDismiss={() => {
          props.togglePopOver();
        }}
        ref={refPopover}
      >
        <IonPage>
          <ModalHeader
            cancelFunction={() => {
              setCreationPopUpVisible(false);
              props.togglePopOver();
            }}
            headerText="create team"
          />
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonInput
                  placeholder="enter team name"
                  id="teamNameInput"
                  type="text"
                  onIonInput={(input) => {
                    if (typeof input.target.value === "string") {
                      teamName.current = input.target.value;
                    }
                  }}
                />
              </IonRow>
              <IonRow className="ion-justify-content-center ion-margin-top">
                <IonButton
                  color="secondary"
                  expand="block"
                  shape="round"
                  fill="solid"
                  onClick={onCreate}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                >
                  {props.buttonLabel}
                </IonButton>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonPage>
      </IonModal>
    </>
  );
};

export default TeamCreationForm;
