import {
  IonButton,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader
} from "@ionic/react";
import React, { useContext, useState } from "react";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import classNames from "classnames";
import { TeamContext } from "../../state/TeamContext";
import { RoutineContext } from "../../state/RoutineContext";
import { UserContext } from "../../state/UserContext";
import { changeCoachName } from "../../api/collections/userCollection";
import { updateTeam } from "../../api/collections/teamCollection";
import { updateRoutine } from "../../api/collections/routineCollection";
import UserInfoSubItem from "./UserInfoSubItem";

function UserInfo() {
  const { selectedTeam } = useContext(TeamContext);
  const { selectedRoutine } = useContext(RoutineContext);
  const { currentUser } = useContext(UserContext);
  const [isUserInfoExpanded, setIsUserInfoExpanded] = useState(false);

  const changeCoach = async (name: string) => {
    if (currentUser) await changeCoachName(currentUser, name);
    else await Promise.reject();
  };

  const changeTeamName = async (name: string) => {
    if (selectedTeam) await updateTeam({ ...selectedTeam, name });
    else await Promise.reject();
  };

  const changeRoutine = async (name: string) => {
    if (!selectedRoutine) await Promise.reject();
    else
      await updateRoutine({
        ...selectedRoutine,
        name
      });
  };

  const changeCount = async (count: string) => {
    if (!selectedRoutine) await Promise.reject();
    else {
      const newNumber = parseInt(count, 10);
      if (Number.isNaN(newNumber)) await Promise.reject();
      else await updateRoutine({ ...selectedRoutine, maxCounts: newNumber });
    }
  };

  const toggleExpandState = () => {
    setIsUserInfoExpanded((oldState) => !oldState);
  };

  return (
    <IonList
      lines="none"
      className={classNames("ion-no-padding", {
        "ion-margin-bottom": isUserInfoExpanded
      })}
    >
      <IonListHeader lines="full">
        <IonLabel>User infos</IonLabel>
        <IonButton
          fill="clear"
          onClick={toggleExpandState}
          style={{ marginTop: "10px" }}
        >
          <IonIcon
            icon={isUserInfoExpanded ? chevronUpOutline : chevronDownOutline}
          />
        </IonButton>
      </IonListHeader>
      {isUserInfoExpanded && (
        <>
          <UserInfoSubItem
            label="Coach"
            placeholder={currentUser?.name}
            emptyMessage="unknown"
            saveFunction={changeCoach}
            isEditDisabled={currentUser === undefined}
          />
          <UserInfoSubItem
            label="Team name"
            placeholder={selectedTeam?.name}
            emptyMessage="no team selected"
            saveFunction={changeTeamName}
            isEditDisabled={selectedTeam === undefined}
          />
          <UserInfoSubItem
            label="routine name"
            placeholder={selectedRoutine?.name}
            emptyMessage="no routine selected"
            saveFunction={changeRoutine}
            isEditDisabled={selectedRoutine === undefined}
          />
          <UserInfoSubItem
            label="max counts"
            placeholder={
              selectedRoutine?.maxCounts
                ? `${selectedRoutine?.maxCounts}`
                : undefined
            }
            emptyMessage="no routine selected"
            saveFunction={changeCount}
            isEditDisabled={selectedRoutine === undefined}
            lines="full"
            className="ion-padding-bottom"
          />
        </>
      )}
    </IonList>
  );
}

export default UserInfo;
