import {
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  setDoc,
  updateDoc
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import CollectionName from "../types/collections.types";
import { CMUser } from "../types/user.types";
import { createCollection } from "./collectionService";
import { functions } from "../firebase";

const userCollection = createCollection<CMUser>(CollectionName.USERS);

export const subscribeUser = (
  userId: string,
  onNewSnapshot: (cmUser: CMUser) => void
) =>
  onSnapshot(
    doc(getFirestore(), "users", userId),
    (userDoc) => onNewSnapshot(userDoc.data() as CMUser),
    (err) => console.log(err)
  );

export const getUser = async (userId: string) => {
  if (!userId) throw Error("No signed in user found");
  const userDocRef = doc<CMUser>(userCollection, userId);
  const userDoc = await getDoc(userDocRef);
  return { ...userDoc.data(), id: userDoc.id } as CMUser;
};

export const addUser = async (user: CMUser) =>
  setDoc(doc(userCollection, user.id), user);

export const removeUser = async () => {
  const user = getAuth().currentUser;
  if (!user) throw Error("No signed in user found");
  const userToRemove = doc(userCollection, user.uid);
  return deleteDoc(userToRemove);
};

export const updateUser = async (updatedUser: Partial<CMUser>) => {
  const user = getAuth().currentUser;
  if (!user) throw Error("No signed in user found");
  const userToUpdate = doc(userCollection, user.uid);
  return updateDoc(userToUpdate, updatedUser);
};

export const changeCoachName = async (user: CMUser, newName: string) => {
  const callable = httpsCallable(functions, "userhttp-updatecoachname");
  return callable({ user: { ...user, name: newName } as CMUser });
};

export default userCollection;
