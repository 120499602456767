import { useIonToast } from "@ionic/react";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification
} from "firebase/auth";
import { errorToast, successToast } from "../toasts/toastTemplates";
import { addUser } from "../api/collections/userCollection";

const useAuth = () => {
  const auth = getAuth();
  const [present] = useIonToast();

  const createAccWithEmailAndPassword = async (
    email: string,
    password: string,
    name: string
  ) => {
    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        await addUser({ id: userCredential.user.uid, name, teamIds: [] });
        await sendEmailVerification(userCredential.user);
        await present(
          successToast(
            "Account created successfully, You need to verify your email address before you can login"
          )
        );
        await auth.signOut();
      })
      .catch((error) => {
        present(errorToast("Error creating account"));
        console.error("Error creating account", error);
      });
  };

  const logout = async () => {
    signOut(auth)
      .then(async () => {
        await present(successToast("Signed out"));
        console.log("Signed out");
      })
      .catch(async (error) => {
        await present(errorToast("Error signing out"));
        console.error("Error signing out", error);
      });
  };

  const login = async (email: string, password: string) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredentials) => {
        if (!userCredentials.user.emailVerified) {
          await signOut(auth);
          await present(errorToast("you need to verify your email"));
        }
      })
      .catch((error) => {
        present(errorToast("Error signing in"));
        console.error("Error signing in", error);
      });
  };

  return {
    createAccWithEmailAndPassword,
    login,
    logout
  };
};

export default useAuth;
