import React from "react";
import { IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import RoutineContextProvider from "./state/RoutineContext";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
/* Cheermaster CSS */
import "./styles/main.scss";

import CMRouter from "./components/router";
import TeamContextProvider from "./state/TeamContext";
import UserContextProvider from "./state/UserContext";
import SettingsContextProvider from "./state/SettingsContext";
import AnimationContextProvider from "./state/AnimationContext";

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <SettingsContextProvider>
        <UserContextProvider>
          <TeamContextProvider>
            <RoutineContextProvider>
              <AnimationContextProvider>
                <CMRouter />
              </AnimationContextProvider>
            </RoutineContextProvider>
          </TeamContextProvider>
        </UserContextProvider>
      </SettingsContextProvider>
    </IonReactRouter>
  </IonApp>
);

export default App;
