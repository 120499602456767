import React, { useContext } from "react";
import { IonButton, IonLabel } from "@ionic/react";
import { SettingsContext } from "../../../state/SettingsContext";

const HorizontalLinesShowButton: React.FC = () => {
  const { areHelpLinesVisible, setHelpLinesVisible } =
    useContext(SettingsContext);
  return (
    <IonButton
      size="small"
      onClick={() => setHelpLinesVisible((prevState) => !prevState)}
    >
      <IonLabel>grid {areHelpLinesVisible ? "off" : "on"}</IonLabel>
    </IonButton>
  );
};

export default HorizontalLinesShowButton;
