const getFirstCharFromFirstTwoNames = (names: string[]): string =>
  names
    .map((name) => name.charAt(0))
    .join("")
    .toUpperCase()
    .substring(0, 2);

const getCounterOfSameInitials = (
  initials: string,
  cheerleadersInitials: string[]
): string => {
  let counter = 0;
  cheerleadersInitials.forEach((value) => {
    if (value.includes(initials)) counter += 1;
  });
  return counter !== 0 ? counter.toString() : "";
};

const createCheerleaderInitials = (
  cheerleaderName: string,
  cheerLeadersInitials: string[]
): string => {
  const cheerleaderNames = cheerleaderName.split(" ");
  const cheerleaderInitials =
    cheerleaderNames.length > 1
      ? getFirstCharFromFirstTwoNames(cheerleaderNames)
      : cheerleaderName.substring(0, 3);

  return (
    cheerleaderInitials +
    getCounterOfSameInitials(cheerleaderInitials, cheerLeadersInitials)
  );
};

export default createCheerleaderInitials;
