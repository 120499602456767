import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import { Feedback } from "../types/feedback.types";

export const submitFeedback = async (feedback: Feedback) => {
  const callable = httpsCallable<
    Feedback,
    { response?: { message?: {} }; error?: { message: {} } }
  >(functions, "feedbackhttp-sendfeedback");
  return callable(feedback);
};

export default submitFeedback;
