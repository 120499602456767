import React, {
  PointerEvent,
  RefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from "react";
import {
  ActivityType,
  LineupEntry,
  Position
} from "../api/types/routine.types";
import {
  getXAndY,
  isInChosenResolution,
  updatePathPointWithXAndY
} from "../components/svg/personCircleHelper";
import { RoutineContext } from "../state/RoutineContext";
import { SettingsContext } from "../state/SettingsContext";
import { AnimationContext } from "../state/AnimationContext";
import {
  getCoordinateAtEndOfPath,
  getCoordinateAtStartOfPath,
  isStartAndEndEqual
} from "../util/pathUtilities";
import useActivityCollection from "./useActivityCollection";

interface UseMovePersonCircleProps {
  isActive: boolean;
  svgRef: RefObject<SVGSVGElement>;
  lineupEntry: LineupEntry;
}

const useMovePersonCircle = (props: UseMovePersonCircleProps) => {
  const [path, setPath] = useState("");
  const prevPos = useRef<Position | undefined>();
  const nextPos = useRef<Position | undefined>();
  const lastPosition = useRef<Position>({});
  const radius = 25;
  const [position, setPosition] = useState<Position>({});
  const [offset, setOffset] = useState<Position>({
    x: radius / 2,
    y: radius / 2
  });
  const [isActive, setIsActive] = useState(props.isActive);
  const { currentActivity, updateLineupEntryInCurrentActivity } =
    useContext(RoutineContext);
  const { findPreviousAndNextLineupEntryForCheer } = useActivityCollection();
  const {
    showStunts,
    isDragAndDropActive,
    isPlayAllRoutineActive,
    setCheerMoveActive,
    areHelpLinesVisible,
    getPosOfHelpLines
  } = useContext(SettingsContext);
  const { getCurrentTimeOfAnimation } = useContext(AnimationContext);

  const handlePointerDownForPath = (e: PointerEvent<SVGCircleElement>) => {
    const { x, y } = getXAndY(e);
    e.currentTarget.setPointerCapture(e.pointerId);
    setIsActive(true);
    setOffset({ x, y });
    setPath(`M ${position.x} ${position.y}`);
    lastPosition.current.x = position.x;
    lastPosition.current.y = position.y;
  };

  const writePath = (x: number, y: number) => {
    if (isInChosenResolution(position, lastPosition)) {
      setPath(`${path} L ${x} ${y}`);
      lastPosition.current.x = x;
      lastPosition.current.y = y;
    }
  };

  const dockToStartOrEndPosition = (x: number, y: number) => {
    let xPos = x;
    let yPos = y;
    if (
      prevPos.current &&
      prevPos.current.x &&
      prevPos.current.y &&
      Math.abs(xPos - prevPos.current.x) < 20 &&
      Math.abs(yPos - prevPos.current.y) < 20
    ) {
      xPos = prevPos.current.x;
      yPos = prevPos.current.y;
    }
    if (
      nextPos.current &&
      nextPos.current.x &&
      nextPos.current.y &&
      Math.abs(xPos - nextPos.current.x) < 20 &&
      Math.abs(yPos - nextPos.current.y) < 20
    ) {
      xPos = nextPos.current.x;
      yPos = nextPos.current.y;
    }
    return { xPos, yPos };
  };

  const dockToHelpLine = (x: number, y: number) => {
    if (!areHelpLinesVisible) return { x, y };
    const positionsOfHorLine = getPosOfHelpLines();
    let dockedPosition = { x, y };
    positionsOfHorLine.forEach((pos) => {
      if (pos.y) {
        if (Math.abs(y - pos.y) < 10)
          dockedPosition = { ...dockedPosition, y: pos.y };
      }
      if (pos.x) {
        if (Math.abs(x - pos.x) < 10)
          dockedPosition = { ...dockedPosition, x: pos.x };
      }
    });
    return dockedPosition;
  };

  const findXAndYForPointerMoveWithDockingDoNext = (
    e: PointerEvent<SVGCircleElement>
  ) => {
    const svgCoordinate = updatePathPointWithXAndY(props.svgRef, e);
    const x = svgCoordinate.x + offset.x! - radius;
    const y = svgCoordinate.y + offset.y! - radius;
    const coordinatesWithDocking = dockToStartOrEndPosition(x, y);
    const horizontalLine = dockToHelpLine(
      coordinatesWithDocking.xPos,
      coordinatesWithDocking.yPos
    );
    return { xPos: horizontalLine.x, yPos: horizontalLine.y };
  };

  const handlePointerMoveForPath = (e: PointerEvent<SVGCircleElement>) => {
    if (!isActive || !props.svgRef.current) return;
    const { xPos, yPos } = findXAndYForPointerMoveWithDockingDoNext(e);
    writePath(xPos, yPos);
    setPosition({ x: xPos, y: yPos });
  };

  const handlePointerMoveForDnD = (e: PointerEvent<SVGCircleElement>) => {
    if (!isActive || !props.svgRef.current) return;
    const { xPos, yPos } = findXAndYForPointerMoveWithDockingDoNext(e);
    setPosition({ x: xPos, y: yPos });
  };

  const handlePointerUpForPath = () => {
    setIsActive(false);
    updateLineupEntryInCurrentActivity({ ...props.lineupEntry, path });
  };

  const handlePointerDownForDnD = (e: PointerEvent<SVGCircleElement>) => {
    e.currentTarget.setPointerCapture(e.pointerId);
    setIsActive(true);
  };

  const handlePointerUpForDnD = () => {
    setIsActive(false);
    const newPosition = `M ${position.x} ${position.y} L ${position.x} ${position.y}`;
    setPath(newPosition);
    updateLineupEntryInCurrentActivity({
      ...props.lineupEntry,
      path: newPosition
    });
  };

  const findPositionsForPrevAndNextLineup = useCallback(
    (
      prevLineup: LineupEntry | undefined,
      nextLineup: LineupEntry | undefined
    ) => {
      if (prevLineup) {
        prevPos.current = getCoordinateAtEndOfPath(prevLineup.path);
      }
      if (nextLineup) {
        nextPos.current = getCoordinateAtStartOfPath(nextLineup.path);
      }
      return { prevPos, nextPos };
    },
    [nextPos, prevPos]
  );

  const handlePointerDown = (e: PointerEvent<SVGCircleElement>) => {
    setCheerMoveActive(true);
    if (getCurrentTimeOfAnimation() || isPlayAllRoutineActive) return;
    isDragAndDropActive
      ? handlePointerDownForDnD(e)
      : handlePointerDownForPath(e);
  };

  const handlePointerMove = (e: PointerEvent<SVGCircleElement>) => {
    if (getCurrentTimeOfAnimation()) return;
    isDragAndDropActive
      ? handlePointerMoveForDnD(e)
      : handlePointerMoveForPath(e);
  };

  const handlePointerUp = () => {
    setCheerMoveActive(false);
    if (getCurrentTimeOfAnimation()) return;
    isDragAndDropActive ? handlePointerUpForDnD() : handlePointerUpForPath();
  };

  const { prevLineup, nextLineup } = useMemo(
    () => findPreviousAndNextLineupEntryForCheer(props.lineupEntry),
    [findPreviousAndNextLineupEntryForCheer, props.lineupEntry]
  );
  const positionsForPrevAndNextLineup = findPositionsForPrevAndNextLineup(
    prevLineup,
    nextLineup
  );

  // Draws a Line between for Transition for before and After Lineups, if not explicit is one given
  const shouldDrawLineForNoGivenPath = useMemo(() => {
    const previousLineupEnd =
      prevLineup && getCoordinateAtEndOfPath(prevLineup?.path);
    const nextLineupStart =
      nextLineup && getCoordinateAtStartOfPath(nextLineup.path);
    const shouldDraw =
      isStartAndEndEqual(props.lineupEntry.path) &&
      previousLineupEnd &&
      nextLineupStart &&
      currentActivity &&
      currentActivity.type === ActivityType.TRANSITION;
    return { shouldDraw, previousLineupEnd, nextLineupStart };
  }, [currentActivity, nextLineup, prevLineup, props.lineupEntry.path]);

  const isPreviousPathEqual = useCallback(
    (prevPosition: React.MutableRefObject<Position | undefined>) => {
      if (!prevPosition) return true;
      const isXEqual =
        prevPosition.current?.x === getCoordinateAtStartOfPath(path).x;
      const isYEqual =
        prevPosition.current?.y === getCoordinateAtStartOfPath(path).y;
      return isXEqual && isYEqual;
    },
    [path]
  );

  const isNextPathEqual = useCallback(
    (nextPosition: React.MutableRefObject<Position | undefined>) => {
      if (!nextPosition) return true;
      const isXEqual =
        nextPosition.current?.x === getCoordinateAtEndOfPath(path)?.x;
      const isYEqual =
        nextPosition.current?.y === getCoordinateAtEndOfPath(path)?.y;
      return isXEqual && isYEqual;
    },
    [path]
  );

  const isPathStartAndEndEqualToSurroundingActivity = useMemo(() => {
    const positions = positionsForPrevAndNextLineup;
    return (
      isPreviousPathEqual(positions.prevPos) &&
      isNextPathEqual(positions.nextPos)
    );
  }, [isNextPathEqual, isPreviousPathEqual, positionsForPrevAndNextLineup]);

  return {
    path,
    setPath,
    position,
    setPosition,
    radius,
    currentActivity,
    showStunts,
    isDragAndDropActive,
    handlePointerMove,
    handlePointerDown,
    handlePointerUp,
    isActive,
    findPositionsForPrevAndNextLineup,
    shouldDrawLineForNoGivenPath,
    positionsForPrevAndNextLineup,
    isPathStartAndEndEqualToSurroundingActivity
  };
};

export default useMovePersonCircle;
