import React, { useCallback, useContext, useMemo, useState } from "react";
import { IonButton, IonIcon, IonItem, IonLabel, IonList } from "@ionic/react";
import { addOutline, eye, eyeOff } from "ionicons/icons";
import CheerleaderConfigurePopOver from "./popOver/CheerleaderConfigurePopOver";
import CheerleaderListItem from "./CheerleaderListItem";
import useCheerSettings from "../../../hooks/useCheerSettings";
import { TeamContext } from "../../../state/TeamContext";
import CheerLeaderAddPopOver from "./popOver/CheerLeaderAddPopOver";
import useAlertWithOkButton from "../../../hooks/alerts/useAlertWithOkButton";
import { RoutineContext } from "../../../state/RoutineContext";
import { Cheerleader } from "../../../api/types/team.types";

const CheerleaderList: React.FC = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isAddCheerPopOverOpen, setIsAddCheerPopOverOpen] = useState(false);

  const {
    cheerleaders,
    selectedCheerleader,
    setSelectedCheerleader,
    selectedTeam
  } = useContext(TeamContext);
  const { selectedRoutine } = useContext(RoutineContext);
  const { toggleAllCheersVisibility } = useCheerSettings();
  const presentAlert = useAlertWithOkButton();

  const allCheersHidden = useMemo(
    () => selectedRoutine?.excludedCheerleader?.length,
    [selectedRoutine?.excludedCheerleader?.length]
  );

  const checkIfHidden = useCallback(
    (cheerleader: Cheerleader) =>
      selectedRoutine?.excludedCheerleader?.find(
        (excluded) => excluded === cheerleader.id
      ) === undefined,
    [selectedRoutine?.excludedCheerleader]
  );

  return (
    <>
      <IonList id="cheerleader-list" className="ion-padding-top">
        <IonItem lines="none">
          <IonButton
            fill="outline"
            onClick={() => {
              if (selectedTeam) setIsAddCheerPopOverOpen(true);
              else presentAlert("Fehler", "Bitte zuerst ein Team auswählen");
            }}
          >
            <IonIcon icon={addOutline} color="primary" />
          </IonButton>
          <IonButton fill="outline" onClick={() => toggleAllCheersVisibility()}>
            <IonIcon
              slot="start"
              icon={allCheersHidden ? eyeOff : eye}
              color="primary"
            />
            <IonLabel>{`${allCheersHidden ? "hide" : "show"} all`}</IonLabel>
          </IonButton>
        </IonItem>
        {cheerleaders.map((cheerleader) => (
          <CheerleaderListItem
            key={`${cheerleader.name}${cheerleader.id.substring(0, 4)}`}
            cheerleader={cheerleader}
            initiales={cheerleader.initials || "UNK"}
            isHidden={checkIfHidden(cheerleader)}
            setPopoverOpen={setPopoverOpen}
            setSelectedCheerleader={setSelectedCheerleader}
          />
        ))}
      </IonList>
      <CheerleaderConfigurePopOver
        selectedCheerleader={selectedCheerleader}
        popoverOpen={popoverOpen}
        setPopoverOpen={setPopoverOpen}
      />
      <CheerLeaderAddPopOver
        setPopoverOpen={setIsAddCheerPopOverOpen}
        popoverOpen={isAddCheerPopOverOpen}
      />
    </>
  );
};

export default CheerleaderList;
