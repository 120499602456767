import React, { useContext } from "react";
import { IonButton, IonLabel } from "@ionic/react";
import { SettingsContext } from "../../../state/SettingsContext";
import { AnimationContext } from "../../../state/AnimationContext";
import { RoutineContext } from "../../../state/RoutineContext";

const ResetCurrentActivityButton: React.FC = () => {
  const { setIsPlayAllRoutineActive } = useContext(SettingsContext);
  const { currentActivity } = useContext(RoutineContext);
  const { resetAnimationsToStart, pauseAnimations } =
    useContext(AnimationContext);
  return (
    <IonButton
      size="small"
      fill="outline"
      disabled={currentActivity === undefined}
      onClick={() => {
        setIsPlayAllRoutineActive(false);
        pauseAnimations();
        resetAnimationsToStart();
      }}
    >
      <IonLabel>reset</IonLabel>
    </IonButton>
  );
};

export default ResetCurrentActivityButton;
