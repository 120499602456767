import { query } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import CollectionName from "../types/collections.types";
import { Cheerleader } from "../types/team.types";
import { createCollection, subscribeCollection } from "./collectionService";
import { functions } from "../firebase";

const getCheerleaderSubcollection = (teamId: string) =>
  createCollection<Cheerleader>(
    `${CollectionName.TEAMS}/${teamId}/${CollectionName.CHEERLEADERS}`
  );

export const subscribeCheerleaders = (
  teamId: string,
  onNewSnapshot: (cheerleaders: Cheerleader[]) => void
) => {
  if (!teamId) throw Error("No teamId provided for query");
  const cheerleaderCollection = getCheerleaderSubcollection(teamId);
  const cheerQuery = query(cheerleaderCollection);
  return subscribeCollection<Cheerleader>(cheerQuery, onNewSnapshot);
};

export const addCheerleader = async (
  teamId: string,
  cheerleader: Omit<Cheerleader, "id">
) => {
  const callable = httpsCallable(
    functions,
    "cheerleaderhttp-createcheerleader"
  );
  await callable({ cheerleader, teamId });
};

export const removeCheerleader = async (
  teamId: string,
  cheerleaderId: string
) => {
  const callable = httpsCallable(
    functions,
    "cheerleaderhttp-deletecheerleader"
  );
  await callable({ cheerleaderId, teamId });
};

export const updateCheerleader = async (
  teamId: string,
  updatedCheerleader: Partial<Cheerleader>
) => {
  const callable = httpsCallable(
    functions,
    "cheerleaderhttp-updatecheerleader"
  );
  await callable({ cheerleader: updatedCheerleader, teamId });
};
