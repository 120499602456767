import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage
} from "@ionic/react";
import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import { RoutineContext } from "../../../state/RoutineContext";
import { Activity, ActivityType } from "../../../api/types/routine.types";
import useActivityCollection from "../../../hooks/useActivityCollection";
import useLineupEntryCollection from "../../../hooks/useLineupEntryCollection";
import useCheerSettings from "../../../hooks/useCheerSettings";
import keyPressListener from "../../../util/hotKey";
import ModalHeader from "../ModalHeader";

type AddActivityModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddActivityModal: React.FC<AddActivityModalProps> = (props) => {
  const [name, setName] = useState("");
  const [counts, setCounts] = useState(8);
  const [transitionCount, setTransitionCount] = useState(0);
  const refPopover = useRef<HTMLIonModalElement>(null);
  const {
    saveCurrentLineupEntriesAndChangeCurrentActivity,
    lineupEntriesForCurrentActivity,
    activities
  } = useContext(RoutineContext);
  const { createActivity, findHighestPositionInRoutine } =
    useActivityCollection();

  const { createLineupEntriesForNewActivityByOldEndPoint } =
    useLineupEntryCollection();

  const { createLineupEntriesForFirstActivity } = useCheerSettings();
  const resetInputs = () => {
    setName("");
    setCounts(8);
    setTransitionCount(0);
  };

  const addActivity = useCallback(async () => {
    const nextPositionInRoutine = findHighestPositionInRoutine() + 1;

    const newLineup: Omit<Activity, "id"> = {
      type: ActivityType.LINEUP,
      counts,
      positionInRoutine: nextPositionInRoutine + 1,
      name
    };
    const newTransition: Omit<Activity, "id"> = {
      type: ActivityType.TRANSITION,
      counts: transitionCount,
      positionInRoutine: nextPositionInRoutine,
      name: `${name}Transition`
    };
    if (activities.length) {
      const activityDocRefTransition = await createActivity(newTransition);
      createLineupEntriesForNewActivityByOldEndPoint(
        lineupEntriesForCurrentActivity,
        activityDocRefTransition
      );
    }
    const activityDocRef = await createActivity(newLineup);
    if (activities.length === 0) {
      createLineupEntriesForFirstActivity(activityDocRef.id);
    }
    createLineupEntriesForNewActivityByOldEndPoint(
      lineupEntriesForCurrentActivity,
      activityDocRef
    );
    console.log(newTransition);
    console.log(newLineup);
    console.log(activityDocRef);
    saveCurrentLineupEntriesAndChangeCurrentActivity({
      ...newLineup,
      id: activityDocRef.id
    });
    resetInputs();
    props.setIsOpen(false);
  }, [
    activities.length,
    counts,
    createActivity,
    createLineupEntriesForFirstActivity,
    createLineupEntriesForNewActivityByOldEndPoint,
    findHighestPositionInRoutine,
    lineupEntriesForCurrentActivity,
    name,
    transitionCount,
    props,
    saveCurrentLineupEntriesAndChangeCurrentActivity
  ]);

  useLayoutEffect(() => {
    if (!props.isOpen) return () => {};
    const onEnterPress = keyPressListener(
      "Enter",
      addActivity,
      refPopover.current
    );
    return () => {
      onEnterPress();
    };
  }, [addActivity, props.isOpen]);

  return (
    <IonModal
      isOpen={props.isOpen}
      onIonModalDidDismiss={() => {
        resetInputs();
        props.setIsOpen(false);
      }}
      canDismiss
      ref={refPopover}
    >
      <IonPage>
        <ModalHeader
          headerText="add activity"
          cancelFunction={() => props.setIsOpen(false)}
        />
        <IonContent>
          <IonItem lines="none">
            <IonLabel id="activity-type">
              <b>Enter Lineup preferences</b>
            </IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonInput
              label="description:"
              labelPlacement="fixed"
              value={name}
              maxlength={50}
              onIonChange={(e) => e.detail.value && setName(e.detail.value)}
              id="activity-name"
            />
          </IonItem>
          <IonItem lines="none">
            <IonInput
              value={counts}
              label="beat count:"
              labelPlacement="fixed"
              type="number"
              inputMode="numeric"
              onIonChange={(e) => e.detail.value && setCounts(+e.detail.value)}
              id="activity-counts"
            />
          </IonItem>
          {activities.length !== 0 && (
            <IonItem lines="none">
              <IonInput
                value={transitionCount}
                label="transition count before:"
                labelPlacement="fixed"
                type="number"
                inputMode="numeric"
                onIonChange={(e) =>
                  e.detail.value && setTransitionCount(+e.detail.value)
                }
                id="transition-counts"
              />
            </IonItem>
          )}
          <IonButton
            onClick={addActivity}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            className="ion-margin-start"
          >
            add activity
          </IonButton>
        </IonContent>
      </IonPage>
    </IonModal>
  );
};

export default AddActivityModal;
