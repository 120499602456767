import React, { useContext } from "react";
import { IonButton, IonIcon } from "@ionic/react";
import {
  addOutline,
  contractOutline,
  expandOutline,
  removeOutline
} from "ionicons/icons";
import { SettingsContext } from "../../../state/SettingsContext";
import { Position } from "../../../api/types/routine.types";
import { RoutineContext } from "../../../state/RoutineContext";

interface ZoomButtonProps {
  setStartCoordinate: React.Dispatch<React.SetStateAction<Required<Position>>>;
}
const ZoomButton: React.FC<ZoomButtonProps> = (props) => {
  const { setStartCoordinate } = props;
  const { setSvgDimension, svgDimension } = useContext(SettingsContext);
  const { carpet } = useContext(RoutineContext);
  const totalWidth = carpet!.width * 100 * carpet!.countOfCarpets;
  const totalHeight = carpet!.length * 100;
  const widthByCarpets =
    totalWidth > totalHeight ? totalWidth + 50 : totalHeight + 500;
  return (
    <div className="ion-float-right">
      <IonButton
        size="small"
        fill="outline"
        onClick={() => {
          setSvgDimension({
            width: widthByCarpets,
            height: carpet!.length * 100 + 100
          });
          setStartCoordinate({ x: -10, y: -10 });
        }}
      >
        <IonIcon
          icon={
            svgDimension.width > widthByCarpets
              ? expandOutline
              : contractOutline
          }
        />
      </IonButton>
      <IonButton
        size="small"
        fill="outline"
        onClick={() =>
          setSvgDimension((prevState) => ({
            height: prevState.height * 0.9,
            width: prevState.width * 0.9
          }))
        }
      >
        <IonIcon icon={addOutline} />
      </IonButton>
      <IonButton
        size="small"
        fill="outline"
        onClick={() =>
          setSvgDimension((prevState) => ({
            height: prevState.height * 1.1,
            width: prevState.width * 1.1
          }))
        }
      >
        <IonIcon icon={removeOutline} />
      </IonButton>
    </div>
  );
};

export default ZoomButton;
