import { Position } from "../api/types/routine.types";

const generateSVGElement = (pathString: string): SVGPathElement => {
  const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttribute("d", pathString);
  return path;
};

export const getCoordinateAtStartOfPath = (pathString: string): Position => {
  if (pathString === "") {
    return { x: 0, y: 0 };
  }
  const path = generateSVGElement(pathString);
  const postion = path.getPointAtLength(0);
  return { x: postion.x, y: postion.y };
};

export const getCoordinateAtEndOfPath = (
  pathString: string
): Position | undefined => {
  if (pathString === "") {
    return { x: 0, y: 0 };
  }
  const path = generateSVGElement(pathString);
  const postion = path.getPointAtLength(path.getTotalLength());
  return { x: postion.x, y: postion.y };
};

export const isStartAndEndEqual = (path: string) => {
  const startPosition = getCoordinateAtStartOfPath(path);
  const endPosition = getCoordinateAtEndOfPath(path);
  if (!startPosition || !endPosition) return false;
  return startPosition.y === endPosition.y && startPosition.x === endPosition.x;
};

export default { getCoordinateAtStartOfPath, getCoordinateAtEndOfPath };
