import { useContext } from "react";
import { Routine } from "../api/types/routine.types";
import { addRoutine } from "../api/collections/routineCollection";
import { TeamContext } from "../state/TeamContext";
import { RoutineContext } from "../state/RoutineContext";

const useRoutineCollection = () => {
  const { selectedTeam } = useContext(TeamContext);
  const { setSelectedRoutine } = useContext(RoutineContext);

  const createRoutine = async (routineValues: Omit<Routine, "id">) => {
    if (!selectedTeam) return;
    const routineRef = await addRoutine({ ...routineValues });
    if (!routineRef) return;
    setSelectedRoutine({
      id: routineRef.id,
      teamId: selectedTeam.id,
      name: routineValues.name,
      maxCounts: routineValues.maxCounts,
      carpetType: routineValues.carpetType,
      excludedCheerleader: []
    });
  };

  return { createRoutine };
};
export default useRoutineCollection;
