import React, { useContext, useRef, useState } from "react";
import { IonButton, IonPopover } from "@ionic/react";
import RoutineCreationForm from "../RoutineCreationForm";
import useRoutineCollection from "../../../hooks/useRoutineCollection";
import { TeamContext } from "../../../state/TeamContext";
import ModalHeader from "../../../components/menu/ModalHeader";
import { Carpet } from "../../../api/types/carpet.types";

const RoutineCreationButton = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef<HTMLIonPopoverElement>(null);
  const { selectedTeam } = useContext(TeamContext);
  const { createRoutine } = useRoutineCollection();

  const openPopover = (
    e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
  ) => {
    popoverRef.current!.event = e;
    setPopoverOpen(true);
  };

  const onRoutineCreate = async (
    name: string,
    maxCounts: number,
    carpetType: Carpet
  ) => {
    if (selectedTeam)
      await createRoutine({
        name,
        teamId: selectedTeam.id,
        maxCounts,
        carpetType,
        excludedCheerleader: []
      });
    setPopoverOpen(false);
  };

  return (
    <>
      <IonButton
        color="primary"
        expand="block"
        shape="round"
        fill="solid"
        onClick={openPopover}
        disabled={!selectedTeam}
      >
        Routine
      </IonButton>
      <IonPopover
        ref={popoverRef}
        isOpen={popoverOpen}
        onDidDismiss={() => setPopoverOpen(false)}
      >
        <ModalHeader
          cancelFunction={() => setPopoverOpen(false)}
          headerText="choose routine"
        />
        <RoutineCreationForm
          buttonLabel="create"
          onButtonClick={onRoutineCreate}
          togglePopOver={() => setPopoverOpen(false)}
        />
      </IonPopover>
    </>
  );
};

export default RoutineCreationButton;
