import { useIonAlert } from "@ionic/react";

const useAlertWithOkButton = () => {
  const [presentAlert] = useIonAlert();
  return async (header: string, message: string) => {
    await presentAlert({
      header,
      message,
      buttons: ["OK"]
    });
  };
};

export default useAlertWithOkButton;
