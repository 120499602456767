import React, { RefObject, useContext, useEffect, useRef } from "react";
import { IonCol, IonContent, IonGrid, IonLabel, IonRow } from "@ionic/react";
import { Position } from "../../api/types/routine.types";
import { RoutineContext } from "../../state/RoutineContext";
import { SettingsContext } from "../../state/SettingsContext";
import useLineup from "../../hooks/useLineup";

interface LineupProps {
  startCoordinate: Required<Position>;
  setStartCoordinate: React.Dispatch<React.SetStateAction<Required<Position>>>;
}

const Lineup: React.FC<LineupProps> = (props) => {
  const svgRef: RefObject<SVGSVGElement> = useRef(null);
  const { startCoordinate, setStartCoordinate } = props;
  const { carpet } = useContext(RoutineContext);
  const { setSvgDimension, svgDimension } = useContext(SettingsContext);
  const {
    renderMatAndCheers,
    handlePointerDown,
    handlePointerMove,
    handlePointerUp,
    renderHorizontalLines,
    renderVerticalHelpLines
  } = useLineup({
    svgRef,
    startCoordinate,
    setStartCoordinate
  });

  useEffect(() => {
    if (svgRef.current) {
      const totalWidth = carpet!.width * 100 * carpet!.countOfCarpets;
      const totalHeight = carpet!.length * 100;
      const widthByCarpets =
        totalWidth > totalHeight ? totalWidth + 50 : totalHeight + 500;
      setSvgDimension({
        width: widthByCarpets,
        height: carpet!.length * 100
      });
      setStartCoordinate({ x: -10, y: -10 });
    }
  }, [carpet, setStartCoordinate, setSvgDimension, svgRef]);

  return carpet!.countOfCarpets ? (
    <svg
      style={{ touchAction: "none" }}
      ref={svgRef}
      width="99%"
      height="99%"
      viewBox={`${startCoordinate.x} ${startCoordinate.y} ${svgDimension.width} ${svgDimension.height}`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerMove={handlePointerMove}
    >
      {renderMatAndCheers()}
      {renderHorizontalLines()}
      {renderVerticalHelpLines()}
    </svg>
  ) : (
    <IonContent class="ion-align-items-center flex-grow-1">
      <IonGrid style={{ height: "100%" }}>
        <IonRow
          class="ion-justify-content-center ion-align-items-center"
          style={{ height: "100%" }}
        >
          <IonCol size="auto">
            <IonLabel style={{ fontSize: 24, fontWeight: "bold" }}>
              Please select a team and a routine to start.
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default Lineup;
