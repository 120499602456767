import { addDoc, deleteDoc, doc, query, updateDoc } from "firebase/firestore";
import CollectionName from "../types/collections.types";
import { Group } from "../types/team.types";
import { createCollection, subscribeCollection } from "./collectionService";

const getGroupSubCollection = (teamId: string) =>
  createCollection<Group>(
    `${CollectionName.TEAMS}/${teamId}/${CollectionName.GROUPS}`
  );

export const subscribeGroup = (
  teamId: string,
  onNewSnapshot: (groups: Group[]) => void
) => {
  if (!teamId) throw Error("No teamId provided for query");
  const groupCollection = getGroupSubCollection(teamId);
  const groupQuery = query(groupCollection);
  return subscribeCollection<Group>(groupQuery, onNewSnapshot);
};

export const addGroup = async (teamId: string, group: Group) => {
  const groupCollection = getGroupSubCollection(teamId);
  addDoc(groupCollection, group);
};

export const removeGroup = async (teamId: string, groupId: string) => {
  const groupCollection = getGroupSubCollection(teamId);
  const groupToRemove = doc(groupCollection, groupId);
  return deleteDoc(groupToRemove);
};

export const updateGroup = async (
  teamId: string,
  groupId: string,
  updatedGroup: Partial<Group>
) => {
  const groupCollection = getGroupSubCollection(teamId);
  const groupToUpdate = doc(groupCollection, groupId);
  return updateDoc(groupToUpdate, updatedGroup);
};
