const keyPressListener = (
  key: string,
  onKeyPress: () => void,
  element: Pick<Element, "addEventListener" | "removeEventListener"> | null
) => {
  const onEvent = (event: Event) => {
    const keyboardEvent = event as KeyboardEvent;
    if (keyboardEvent.key === key) {
      onKeyPress();
    }
  };
  if (element) {
    element.addEventListener("keydown", onEvent);
    return () => element.removeEventListener("keydown", onEvent);
  }
  return () => {};
};

export default keyPressListener;
