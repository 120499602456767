import { doc, documentId, getDoc, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import CollectionName from "../types/collections.types";
import { createCollection, subscribeCollection } from "./collectionService";
import { Team } from "../types/team.types";
import { functions } from "../firebase";

const teamCollection = createCollection<Team>(CollectionName.TEAMS);

export const getTeamById = async (teamId: string) => {
  if (!teamId) throw Error("No team id provided");
  const teamDocRef = doc<Team>(teamCollection, teamId);
  const teamDoc = await getDoc(teamDocRef);
  teamDoc.data();
  return { ...teamDoc.data(), id: teamDoc.id } as Team;
};

export const subscribeTeams = (
  teamIds: string[],
  onNewSnapshot: (teams: Team[]) => void
) => {
  const teamQuery = query(teamCollection, where(documentId(), "in", teamIds));
  return subscribeCollection<Team>(teamQuery, onNewSnapshot);
};

export const addTeam = async (team: Partial<Team>, userId: string) => {
  const teamToCreate: Omit<Team, "id"> = {
    name: team.name!
  };
  const callable = httpsCallable(functions, "teamhttp-createteam");
  await callable({ team: teamToCreate, userId });
};

export const removeTeam = async (teamId: string, userId: string) => {
  const callable = httpsCallable(functions, "teamhttp-deleteteam");
  await callable({ teamId, userId });
};

export const updateTeam = async (updatedTeam: Partial<Team>) => {
  const callable = httpsCallable(functions, "teamhttp-updateteam");
  await callable({ team: updatedTeam });
};

export default teamCollection;
