import React from "react";

interface CarpetProps {
  height: number;
  width: number;
  color: string;
  positionX: number;
  positionY: number;
  stroke?: string;
}

const Carpet: React.FC<CarpetProps> = (props) => (
  <rect
    x={props.positionX}
    y={props.positionY}
    width={props.width}
    height={props.height}
    fill={props.color}
    opacity={20}
    stroke={props.stroke || "black"}
  />
);

export default Carpet;
