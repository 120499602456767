import {
  IonButton,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  SegmentValue
} from "@ionic/react";
import { useContext, useState } from "react";
import { SettingsContext } from "../../../state/SettingsContext";

const ToggleDragAndRouteButton: React.FC = () => {
  const { setIsDragAndDropActive } = useContext(SettingsContext);
  const [chosenSegment, setChosenSegment] = useState<SegmentValue>("drag");

  const handleToggleChange = () => {
    setIsDragAndDropActive((prevState) => !prevState);
  };

  return (
    <IonButton
      fill="clear"
      size="small"
      className="ion-no-padding"
      onClick={(e) => e.preventDefault()}
    >
      <IonSegment
        onIonChange={(e) => {
          if (!e.detail.value) return;
          setChosenSegment(e.detail.value);
          handleToggleChange();
        }}
        value={chosenSegment}
        mode="ios"
      >
        <IonSegmentButton value="drag" mode="ios">
          <IonLabel>drag</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="route" mode="ios">
          <IonLabel>route</IonLabel>
        </IonSegmentButton>
      </IonSegment>
    </IonButton>
  );
};

export default ToggleDragAndRouteButton;
