import React, { ReactNode, useMemo, useRef, useState } from "react";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { Position } from "../api/types/routine.types";

gsap.registerPlugin(MotionPathPlugin);
export interface Dimensions {
  height: number;
  width: number;
}
interface SettingsContextProps {
  showStunts: boolean;
  setShowStunts: React.Dispatch<React.SetStateAction<boolean>>;
  isPlayAllRoutineActive: boolean;
  setIsPlayAllRoutineActive: React.Dispatch<React.SetStateAction<boolean>>;
  svgDimension: Dimensions;
  setSvgDimension: React.Dispatch<React.SetStateAction<Dimensions>>;
  cheerMoveActive: boolean;
  setCheerMoveActive: React.Dispatch<React.SetStateAction<boolean>>;
  isDragAndDropActive: boolean;
  setIsDragAndDropActive: React.Dispatch<React.SetStateAction<boolean>>;
  areHelpLinesVisible: boolean;
  setHelpLinesVisible: React.Dispatch<React.SetStateAction<boolean>>;
  getPosOfHelpLines: () => Position[];
  setPosOfHelpLines: (positions: Array<Position>) => void;
}

export const SettingsContext = React.createContext<SettingsContextProps>({
  showStunts: true,
  setShowStunts: () => undefined,
  isPlayAllRoutineActive: false,
  setIsPlayAllRoutineActive: () => undefined,
  svgDimension: { height: 0, width: 0 },
  setSvgDimension: () => undefined,
  cheerMoveActive: false,
  setCheerMoveActive: () => undefined,
  isDragAndDropActive: true,
  setIsDragAndDropActive: () => {},
  areHelpLinesVisible: false,
  setHelpLinesVisible: () => {},
  getPosOfHelpLines: (): Array<Position> => [],
  setPosOfHelpLines: () => {}
});

const SettingsContextProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [showStunts, setShowStunts] = useState<boolean>(false);
  const [areHelpLinesVisible, setHelpLinesVisible] = useState<boolean>(false);
  const [isDragAndDropActive, setIsDragAndDropActive] = useState<boolean>(true);
  const [isPlayAllRoutineActive, setIsPlayAllRoutineActive] = useState(false);
  const [svgDimension, setSvgDimension] = useState<Dimensions>({
    height: 0,
    width: 0
  });
  const [cheerMoveActive, setCheerMoveActive] = useState<boolean>(false);
  const posOfHelpLines = useRef<Array<Position>>([]);

  const getPosOfHelpLines = () => posOfHelpLines.current;
  /**
   * setPosOfHelpLines nur mit Arrays befüllen, die jeweils nur x oder nur y Werte beinhalten
   */
  const setPosOfHelpLines = (helpLines: Array<Position>) => {
    posOfHelpLines.current = posOfHelpLines.current
      .filter((pos) => (helpLines[0].y ? pos.x : pos.y))
      .concat(helpLines);
  };

  const context = useMemo(
    () => ({
      showStunts,
      setShowStunts,
      isDragAndDropActive,
      setIsDragAndDropActive,
      isPlayAllRoutineActive,
      setIsPlayAllRoutineActive,
      svgDimension,
      setSvgDimension,
      cheerMoveActive,
      setCheerMoveActive,
      areHelpLinesVisible,
      setHelpLinesVisible,
      getPosOfHelpLines,
      setPosOfHelpLines
    }),
    [
      showStunts,
      isDragAndDropActive,
      isPlayAllRoutineActive,
      svgDimension,
      cheerMoveActive,
      areHelpLinesVisible
    ]
  );

  return (
    <SettingsContext.Provider value={context}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContextProvider;
