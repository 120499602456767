import React, { useContext } from "react";
import { IonButton } from "@ionic/react";
import useAuth from "../../hooks/useAuth";
import { RoutineContext } from "../../state/RoutineContext";
import { TeamContext } from "../../state/TeamContext";

const LogoutButton = () => {
  const { logout } = useAuth();
  const { resetAllRoutineStates } = useContext(RoutineContext);
  const { resetAllTeamStates } = useContext(TeamContext);
  const onLogout = () => {
    resetAllRoutineStates();
    resetAllTeamStates();
    logout();
  };
  return (
    <IonButton
      color="danger"
      fill="outline"
      expand="full"
      shape="round"
      onClick={onLogout}
    >
      <b>Logout</b>
    </IonButton>
  );
};

const AccountActionButton = () => <LogoutButton />;

export default AccountActionButton;
