import React, {
  RefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from "react";
import { LineupEntry, Position } from "../api/types/routine.types";
import PersonCircle from "../components/svg/PersonCircle";
import useRoutineHandler from "./useRoutineHandler";
import { SettingsContext } from "../state/SettingsContext";
import Carpet from "../components/svg/Carpet";
import { RoutineContext } from "../state/RoutineContext";
import { AnimationContext } from "../state/AnimationContext";
import HelpLine from "../components/svg/HelpLine";

interface UseLineupProps {
  svgRef: RefObject<SVGSVGElement>;
  startCoordinate: Required<Position>;
  setStartCoordinate: React.Dispatch<React.SetStateAction<Required<Position>>>;
}
const useLineup = (props: UseLineupProps) => {
  const pointZero: Required<Position> = { x: 0, y: 0 };
  const [isActive, setIsActive] = useState<boolean>(false);
  const coordinateBeforeMove = useRef<Required<Position>>(pointZero);
  const coordinateOnPointerDown = useRef<Required<Position>>(pointZero);
  const currentAnimated = useRef<string>();
  const { getCheersForLineup } = useRoutineHandler();
  const cheersForLineup = getCheersForLineup();
  const { lineupEntriesForCurrentActivity, carpet, activities } =
    useContext(RoutineContext);
  const { resetAnimations } = useContext(AnimationContext);
  const {
    cheerMoveActive,
    areHelpLinesVisible,
    setPosOfHelpLines,
    setSvgDimension
  } = useContext(SettingsContext);
  const [pointer, setPointer] = useState<React.PointerEvent<SVGSVGElement>[]>(
    []
  );
  const [prevDiff, setPrevDiff] = useState(0);

  const renderCheer = useCallback(
    (lineupEntry: LineupEntry) => {
      const cheer = cheersForLineup.get(lineupEntry.id);
      if (!cheer) return [];
      return (
        <PersonCircle
          key={`${cheer.initials}${cheer.id}${currentAnimated.current}`}
          cheerleader={cheer}
          lineupEntry={lineupEntry}
          svgRef={props.svgRef}
        />
      );
    },
    [cheersForLineup, props.svgRef]
  );
  const numberOfCarpets = useMemo(
    () => (carpet?.width && carpet.countOfCarpets ? carpet.countOfCarpets : 1),
    [carpet]
  );

  const renderMat = useCallback(() => {
    if (!carpet) return [];
    const matOfCarpets = [];
    for (let i = 0; i < numberOfCarpets; i += 1) {
      matOfCarpets.push(
        <Carpet
          key={`CarpterNr${i}`}
          height={carpet.length * 100}
          width={carpet.width * 100}
          color={i % 2 === 0 ? "#C0C0C0" : "#D3D3D3"}
          positionX={carpet.width * 100 * i}
          positionY={0}
        />
      );
    }
    return matOfCarpets;
  }, [carpet, numberOfCarpets]);

  const renderVerticalHelpLines = useCallback(() => {
    if (!carpet || !areHelpLinesVisible) return undefined;
    const verticalHelpLines: JSX.Element[] = [];
    const positionsOfVerticalLines: Position[] = [{ x: 0.1 }];
    const percentageOfCarpets = [0.25, 0.5, 0.75, 1];
    for (let i = 0; i < numberOfCarpets; i += 1) {
      percentageOfCarpets.forEach((percentage) => {
        const x = (carpet.width * i + carpet.width * percentage) * 100;
        positionsOfVerticalLines.push({ x });
        percentage !== 1 &&
          verticalHelpLines.push(
            <HelpLine
              height={carpet.length * 100}
              xPosition={x}
              key={`HelpLineNr${x}`}
            />
          );
      });
    }
    setPosOfHelpLines(positionsOfVerticalLines);
    return verticalHelpLines;
  }, [areHelpLinesVisible, carpet, numberOfCarpets, setPosOfHelpLines]);

  const renderHorizontalLines = useCallback(() => {
    if (!carpet || !areHelpLinesVisible) return undefined;
    const lineCounts = 6;
    const heightBetweenLines = (carpet.length * 100) / lineCounts;
    const renderedLines: Array<JSX.Element> = [];
    const positionsOfHorizontalLines: Position[] = [];
    for (let i = 1; i < lineCounts; i += 1) {
      positionsOfHorizontalLines.push({ y: heightBetweenLines * i });
      renderedLines.push(
        <Carpet
          key={`horLineAt${heightBetweenLines * i}`}
          height={1}
          width={carpet.width * 100 * numberOfCarpets}
          color="grey"
          positionX={0}
          positionY={heightBetweenLines * i}
          stroke="none"
        />
      );
    }
    setPosOfHelpLines(positionsOfHorizontalLines);
    return renderedLines;
  }, [areHelpLinesVisible, carpet, numberOfCarpets, setPosOfHelpLines]);

  const killAnimations = useCallback(() => {
    if (
      lineupEntriesForCurrentActivity &&
      lineupEntriesForCurrentActivity[0] &&
      currentAnimated.current !== lineupEntriesForCurrentActivity[0].id
    )
      resetAnimations();
  }, [lineupEntriesForCurrentActivity, resetAnimations]);

  const setCurrentActivityId = useCallback(() => {
    if (lineupEntriesForCurrentActivity && lineupEntriesForCurrentActivity[0])
      currentAnimated.current = lineupEntriesForCurrentActivity[0].id;
  }, [lineupEntriesForCurrentActivity]);

  const renderMatAndCheers = useCallback(() => {
    killAnimations();
    setCurrentActivityId();
    let svgsToRender;
    svgsToRender = renderMat();
    if (activities.length)
      svgsToRender = [
        ...svgsToRender,
        lineupEntriesForCurrentActivity.map(renderCheer)
      ];
    return svgsToRender;
  }, [
    activities,
    killAnimations,
    lineupEntriesForCurrentActivity,
    renderCheer,
    renderMat,
    setCurrentActivityId
  ]);
  const handleZoom = (movedPointer: React.PointerEvent<SVGSVGElement>) => {
    setPointer((prev) =>
      prev.map((prevPointer) =>
        prevPointer.pointerId === movedPointer.pointerId
          ? movedPointer
          : prevPointer
      )
    );
    const curDiff = Math.abs(pointer[0].clientX - pointer[1].clientX);
    if (prevDiff > 0) {
      const zoom = 5;
      if (curDiff > prevDiff) {
        setSvgDimension((prev) => ({
          width: prev.width - zoom > 0 ? prev.width - zoom : prev.width,
          height: prev.height - zoom > 0 ? prev.height - zoom : prev.height
        }));
      }
      if (curDiff < prevDiff) {
        setSvgDimension((prev) => ({
          width: prev.width + zoom,
          height: prev.height + zoom
        }));
      }
    }
    setPrevDiff(curDiff);
  };

  const handleMove = (e: React.PointerEvent<SVGSVGElement>) => {
    if (!isActive || cheerMoveActive) return;
    props.setStartCoordinate({
      x:
        coordinateBeforeMove.current.x +
        (coordinateOnPointerDown.current.x - e.clientX) * 3,
      y:
        coordinateBeforeMove.current.y +
        (coordinateOnPointerDown.current.y - e.clientY) * 3
    });
  };

  const handlePointerDown = (e: React.PointerEvent<SVGSVGElement>) => {
    setPointer((prevState) => prevState.concat([e]));
    if (cheerMoveActive) return;
    setIsActive(true);
    coordinateOnPointerDown.current = { x: e.clientX, y: e.clientY };
    coordinateBeforeMove.current = props.startCoordinate;
  };

  const handlePointerUp = (e: React.PointerEvent<SVGSVGElement>) => {
    setPointer((prevState) =>
      prevState.filter((ev) => ev.pointerId !== e.pointerId)
    );
    setPrevDiff(0);
    setIsActive(false);
  };
  const handlePointerMove = (e: React.PointerEvent<SVGSVGElement>) => {
    if (pointer.length === 2) {
      handleZoom(e);
    } else {
      handleMove(e);
    }
  };

  return {
    renderMatAndCheers,
    renderVerticalHelpLines,
    renderHorizontalLines,
    handlePointerDown,
    handlePointerMove,
    handlePointerUp,
    numberOfCarpets,
    pointZero
  };
};

export default useLineup;
