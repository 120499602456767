import React from "react";

interface HelpLineProps {
  height: number;
  xPosition: number;
}

const HelpLine: React.FC<HelpLineProps> = (props) => (
  <line
    x1={props.xPosition}
    x2={props.xPosition}
    y1={0}
    y2={props.height}
    stroke="grey"
    strokeDasharray="15"
  />
);

export default HelpLine;
