import { addDoc, deleteDoc, doc, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import CollectionName from "../types/collections.types";
import { Routine } from "../types/routine.types";
import { createCollection, subscribeCollection } from "./collectionService";
import { functions } from "../firebase";

const routineCollection = createCollection<Routine>(CollectionName.ROUTINES);

export const subscribeRoutine = (
  teamId: string,
  onNewSnapshot: (routines: Routine[]) => void
) => {
  if (!teamId) throw Error("No teamId provided for query");
  const routineQuery = query(routineCollection, where("teamId", "==", teamId));
  return subscribeCollection<Routine>(routineQuery, onNewSnapshot);
};

export const addRoutine = async (routine: Omit<Routine, "id">) =>
  addDoc(routineCollection, routine);

export const removeRoutine = async (routineId: string) => {
  const routineToRemove = doc(routineCollection, routineId);
  return deleteDoc(routineToRemove);
};

export const updateRoutine = async (updatedRoutine: Routine) => {
  const callable = httpsCallable(functions, "routinehttp-updateroutine");
  await callable({ routine: updatedRoutine });
};

export default routineCollection;
