import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { Unsubscribe } from "firebase/firestore";
import { Activity, LineupEntry, Routine } from "../api/types/routine.types";
import { subscribeRoutine } from "../api/collections/routineCollection";
import { subscribeActivities } from "../api/collections/activityCollection";
import { subscribeLineupEntries } from "../api/collections/lineupEntryCollection";
import { TeamContext } from "./TeamContext";
import { Carpet } from "../api/types/carpet.types";
import { getCarpetByCarpetType } from "../util/carpetUtilities";
import useCurrentRoutineHandler from "../hooks/useCurrentRoutineHandler";

interface RoutineContextType {
  routines: Routine[];
  selectedRoutine?: Routine;
  setSelectedRoutine: (routineId: Routine | undefined) => void;
  activities: Activity[];
  currentActivity?: Activity;
  startTimeForCurrentActivity: number;
  calculateStartTimeForGivenActivity: (givenActivity: Activity) => number;
  saveCurrentLineUpEntries: () => void;
  saveCurrentLineupEntriesAndChangeCurrentActivity: (
    activity?: Activity
  ) => void;
  lineupEntries: LineupEntry[];
  carpet?: Carpet;
  lineupEntriesForCurrentActivity: LineupEntry[];
  updateLineupEntryInCurrentActivity: (
    lineupEntryToUpdate: LineupEntry
  ) => void;
  changeToNextActivity: (activityId: string) => boolean;
  usedActivityCounts: number;
  updateWholeActivity: (
    activityToUpdateId: string,
    newActivityName: string | undefined,
    newActivityCount: number | undefined
  ) => void;
  deleteActivityAndUpdateTheFollowing: (activityToUpdateId: string) => void;
  resetAllRoutineStates: () => void;
}

export const RoutineContext = React.createContext<RoutineContextType>({
  updateWholeActivity: () => {},
  routines: [],
  selectedRoutine: undefined,
  setSelectedRoutine: () => {},
  activities: [],
  startTimeForCurrentActivity: 0,
  calculateStartTimeForGivenActivity: () => 0,
  currentActivity: undefined,
  saveCurrentLineUpEntries: () => undefined,
  saveCurrentLineupEntriesAndChangeCurrentActivity: () => {},
  lineupEntries: [],
  carpet: undefined,
  lineupEntriesForCurrentActivity: [],
  updateLineupEntryInCurrentActivity: () => undefined,
  changeToNextActivity: () => false,
  usedActivityCounts: 0,
  deleteActivityAndUpdateTheFollowing: () => {},
  resetAllRoutineStates: () => {}
});

const RoutineContextProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [routines, setRoutines] = useState<Routine[]>([]);
  const [selectedRoutine, setSelectedRoutine] = useState<Routine>();
  const [activities, setActivities] = useState<Activity[]>([]);
  const [currentActivity, setCurrentActivity] = useState<Activity>();
  const [startTimeForCurrentActivity, setStartTimeForCurrentActivity] =
    useState(0);
  const [lineupEntries, setLineupEntries] = useState<LineupEntry[]>([]);
  const [changedCurrentLineupEntries, setChangedCurrentLineupEntries] =
    useState<LineupEntry[]>([]);
  const [lineupEntriesForCurrentActivity, setLineupEntriesForCurrentActivity] =
    useState<LineupEntry[]>([]);
  const [carpet, setCarpet] = useState<Carpet>(getCarpetByCarpetType("none")!);
  const { selectedTeam } = useContext(TeamContext);
  const {
    updateWholeActivity,
    updateLineupEntryInCurrentActivity,
    saveCurrentLineUpEntries,
    changeToNextActivity,
    saveCurrentLineupEntriesAndChangeCurrentActivity,
    deleteActivityAndUpdateTheFollowing
  } = useCurrentRoutineHandler({
    setLineupEntriesForCurrentActivity,
    changedCurrentLineupEntries,
    setChangedCurrentLineupEntries,
    selectedRoutine,
    currentActivity,
    setCurrentActivity,
    activities,
    setActivities
  });

  const resetAllRoutineStates = () => {
    setCarpet(getCarpetByCarpetType("none")!);
    setLineupEntriesForCurrentActivity([]);
    setChangedCurrentLineupEntries([]);
    setLineupEntries([]);
    setActivities([]);
    setSelectedRoutine(undefined);
  };

  const usedActivityCounts = useMemo(() => {
    let usedCounts = -1;
    if (!activities.length) return usedCounts;
    activities.forEach((activity) => {
      usedCounts += activity.counts;
    });
    return usedCounts;
  }, [activities]);

  const calculateStartTimeForGivenActivity = useCallback(
    (givenActivity: Activity) => {
      let startTime = 1;
      activities.forEach((activity) => {
        if (activity.positionInRoutine < givenActivity.positionInRoutine) {
          startTime += activity.counts;
        }
      });
      return startTime;
    },
    [activities]
  );

  const context: RoutineContextType = useMemo(
    () => ({
      routines,
      selectedRoutine,
      setSelectedRoutine,
      activities,
      currentActivity,
      startTimeForCurrentActivity,
      calculateStartTimeForGivenActivity,
      saveCurrentLineUpEntries,
      saveCurrentLineupEntriesAndChangeCurrentActivity,
      lineupEntries,
      carpet,
      lineupEntriesForCurrentActivity,
      updateLineupEntryInCurrentActivity,
      changeToNextActivity,
      usedActivityCounts,
      updateWholeActivity,
      deleteActivityAndUpdateTheFollowing,
      resetAllRoutineStates
    }),
    [
      routines,
      selectedRoutine,
      activities,
      currentActivity,
      startTimeForCurrentActivity,
      calculateStartTimeForGivenActivity,
      saveCurrentLineUpEntries,
      saveCurrentLineupEntriesAndChangeCurrentActivity,
      lineupEntries,
      carpet,
      lineupEntriesForCurrentActivity,
      updateLineupEntryInCurrentActivity,
      changeToNextActivity,
      usedActivityCounts,
      updateWholeActivity,
      deleteActivityAndUpdateTheFollowing
    ]
  );

  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined;
    if (selectedTeam) {
      setSelectedRoutine(undefined);
      console.log("creating new routines subscription for team");
      unsubscribe = subscribeRoutine(selectedTeam.id, (newRoutines) => {
        console.log("routines updated");
        setRoutines(newRoutines);
        setSelectedRoutine((prevState) =>
          newRoutines.find((routine) => routine.id === prevState?.id)
        );
      });
    }
    if (!unsubscribe) setRoutines([]);
    return () => {
      console.log("unsubscribe Routine");
      unsubscribe && unsubscribe();
    };
  }, [selectedTeam]);

  useEffect(() => {
    console.log("creating new activities subscription");
    console.log("creating new lineupEntries subscription");
    let unsubActivities: Unsubscribe;
    let unsubLineupEntries: Unsubscribe;
    if (selectedRoutine) {
      unsubActivities = subscribeActivities(
        selectedRoutine.id,
        (newActivities) => {
          console.log("activities updated", newActivities);
          setActivities(newActivities);
        }
      );
      unsubLineupEntries = subscribeLineupEntries(
        selectedRoutine.id,
        (newLineupEntries) => {
          console.log("lineupEntries updated");
          setLineupEntries(newLineupEntries);
        }
      );
      setCarpet(
        typeof selectedRoutine.carpetType === "string"
          ? getCarpetByCarpetType(selectedRoutine.carpetType)
          : selectedRoutine.carpetType
      );
    } else {
      setActivities([]);
      setCurrentActivity(undefined);
      setLineupEntriesForCurrentActivity([]);
    }
    return () => {
      unsubActivities && unsubActivities();
      unsubLineupEntries && unsubLineupEntries();
    };
  }, [selectedRoutine]);

  useEffect(() => {
    if (!currentActivity) {
      setLineupEntriesForCurrentActivity([]);
      setStartTimeForCurrentActivity(0);
    } else {
      setStartTimeForCurrentActivity(
        calculateStartTimeForGivenActivity(currentActivity)
      );
      setLineupEntriesForCurrentActivity(
        lineupEntries.filter(
          (lineUp) => lineUp.activityId === currentActivity.id
        )
      );
    }
  }, [calculateStartTimeForGivenActivity, currentActivity, lineupEntries]);

  return (
    <RoutineContext.Provider value={context}>
      {children}
    </RoutineContext.Provider>
  );
};

export default RoutineContextProvider;
