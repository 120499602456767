import React, { useContext, useState } from "react";
import { IonButton, IonItem, IonList } from "@ionic/react";
import { RoutineContext } from "../../state/RoutineContext";
import { removeRoutine } from "../../api/collections/routineCollection";
import ItemsWithFunctionAndDelete from "../../components/lists/ItemsWithFunctionAndDelete";
import RoutineCreationPopover from "../../components/dialogs/RoutineCreationPopover";
import { TeamContext } from "../../state/TeamContext";
import useAlertWithOkButton from "../../hooks/alerts/useAlertWithOkButton";
import { Carpet } from "../../api/types/carpet.types";

type RoutineCreationFormProps = {
  buttonLabel: string;
  onButtonClick: (name: string, maxCounts: number, carpetType: Carpet) => void;
  togglePopOver: () => void;
};

const RoutineCreationForm: React.FC<RoutineCreationFormProps> = (props) => {
  const {
    routines,
    resetAllRoutineStates,
    setSelectedRoutine,
    selectedRoutine
  } = useContext(RoutineContext);
  const { selectedTeam } = useContext(TeamContext);
  const presentAlert = useAlertWithOkButton();
  const [isCreationPopUpVisible, setCreationPopUpVisible] =
    useState<boolean>(false);
  const removeRoutineFunction = async (routineIdToDelete: string) => {
    await removeRoutine(routineIdToDelete);
    if (selectedRoutine?.id === routineIdToDelete) {
      resetAllRoutineStates();
    }
  };
  return (
    <>
      <IonList>
        <ItemsWithFunctionAndDelete
          items={routines}
          setChosenItem={setSelectedRoutine}
          togglePopOver={props.togglePopOver}
          deleteFunction={removeRoutineFunction}
          idOfCurrentChosenItem={selectedRoutine?.id}
        />
        <IonItem>
          <IonButton
            color="secondary"
            expand="block"
            shape="round"
            fill="solid"
            onClick={() => {
              if (selectedTeam) setCreationPopUpVisible(true);
              else presentAlert("Fehler", "Bitte zuerst ein Team auswählen");
            }}
          >
            {props.buttonLabel}
          </IonButton>
        </IonItem>
      </IonList>
      <RoutineCreationPopover
        isOpen={isCreationPopUpVisible}
        togglePopOver={props.togglePopOver}
        onButtonClick={props.onButtonClick}
        setCreationPopUpVisible={setCreationPopUpVisible}
        buttonLabel={props.buttonLabel}
      />
    </>
  );
};

export default RoutineCreationForm;
