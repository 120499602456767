import React, { useContext } from "react";
import { IonButton, IonLabel } from "@ionic/react";
import { SettingsContext } from "../../../state/SettingsContext";
import { AnimationContext } from "../../../state/AnimationContext";
import { RoutineContext } from "../../../state/RoutineContext";

const PlayPauseButton: React.FC = () => {
  const { setIsPlayAllRoutineActive, isPlayAllRoutineActive } =
    useContext(SettingsContext);
  const { playAnimations, pauseAnimations } = useContext(AnimationContext);
  const { currentActivity } = useContext(RoutineContext);

  return (
    <IonButton
      fill="outline"
      size="small"
      disabled={currentActivity === undefined}
      onClick={() => {
        if (!isPlayAllRoutineActive) {
          setIsPlayAllRoutineActive(true);
          playAnimations();
          return;
        }
        setIsPlayAllRoutineActive(false);
        pauseAnimations();
      }}
    >
      <IonLabel>{isPlayAllRoutineActive ? "Pause" : "Play"}</IonLabel>
    </IonButton>
  );
};

export default PlayPauseButton;
