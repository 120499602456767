import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonLoading,
  IonRow,
  useIonToast
} from "@ionic/react";
import useAuth from "../../hooks/useAuth";
import NavLink from "../../components/router/NavLinks";
import EmailPasswordForm from "./EmailPasswordForm";

const SignupCard: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [present] = useIonToast();

  const { createAccWithEmailAndPassword } = useAuth();

  const handleSignup = async (
    email: string,
    password: string,
    name?: string,
    hasAcceptedProductChange?: boolean
  ) => {
    if (!name || !name.length) {
      await present("Please enter your name", 2000);
      return;
    }
    if (!hasAcceptedProductChange) {
      await present("You have to accept the agreement", 2000);
      return;
    }
    setLoading(true);
    await createAccWithEmailAndPassword(email, password, name);
    setLoading(false);
  };

  if (loading) return <IonLoading isOpen={loading} />;

  return (
    <IonCard className="loginCard">
      <div className="loginCard">
        <IonCardHeader>
          <IonCardTitle>Signup</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <EmailPasswordForm
            buttonLabel="Signup"
            onButtonClick={handleSignup}
            register
          />
          <IonRow>
            <IonCol>
              <p style={{ fontSize: "small" }}>
                Already have an account? <Link to={NavLink.LOGIN}>Login!</Link>
              </p>
              <p style={{ fontSize: "small" }}>
                By clicking SIGNUP you agree to our{" "}
                <a href="https://cheermaster.org/agb">Policy</a>
              </p>
            </IonCol>
          </IonRow>
        </IonCardContent>
      </div>
    </IonCard>
  );
};

export default SignupCard;
