import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { gsap } from "gsap";
import { RoutineContext } from "./RoutineContext";
import { SettingsContext } from "./SettingsContext";

interface AnimationContextType {
  saveAnimationForCheerleader: (tween: gsap.core.Tween, id: string) => void;
  playAnimations: () => void;
  pauseAnimations: () => void;
  resetAnimations: () => void;
  resetAnimationsToStart: () => void;
  getCurrentTimeOfAnimation: () => number;
  playNextAnimation: (activityId: string) => void;
  currentTime: number;
  setCurrentTime: React.Dispatch<React.SetStateAction<number>>;
  playSpeed: number;
  changePlaySpeed: (setData: number) => void;
}

export const AnimationContext = React.createContext<AnimationContextType>({
  saveAnimationForCheerleader: () => {},
  playAnimations: () => {},
  pauseAnimations: () => {},
  resetAnimations: () => {},
  playNextAnimation: () => {},
  getCurrentTimeOfAnimation: () => 0,
  resetAnimationsToStart: () => {},
  currentTime: 0,
  setCurrentTime: () => {},
  playSpeed: 1,
  changePlaySpeed: () => {}
});

const AnimationContextProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const animationsForCurrentLineup = useRef(new Map<string, gsap.core.Tween>());
  const { changeToNextActivity, calculateStartTimeForGivenActivity } =
    useContext(RoutineContext);
  const { setIsPlayAllRoutineActive, isPlayAllRoutineActive } =
    useContext(SettingsContext);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const { currentActivity } = useContext(RoutineContext);
  const [playSpeed, setPlaySpeed] = useState<number>(1);

  const killExistingAnimation = (id: string) => {
    const foundAnimation = animationsForCurrentLineup.current.get(id);
    if (foundAnimation) {
      foundAnimation.kill();
    }
  };

  const saveAnimationForCheerleader = useCallback(
    (tween: gsap.core.Tween, id: string) => {
      killExistingAnimation(id);
      animationsForCurrentLineup.current.set(id, tween);
    },
    []
  );

  const playAnimations = useCallback(() => {
    setIsPlayAllRoutineActive(true);
    Array.from(animationsForCurrentLineup.current.values()).forEach(
      (animation) => {
        animation.timeScale(playSpeed);
        animation.play();
      }
    );
  }, [playSpeed, setIsPlayAllRoutineActive]);

  const resetAnimationsToStart = useCallback(() => {
    Array.from(animationsForCurrentLineup.current.values()).forEach(
      (animation) => {
        animation.time(0);
      }
    );
    setCurrentTime(0);
  }, []);

  const pauseAnimations = useCallback(() => {
    setIsPlayAllRoutineActive(false);
    Array.from(animationsForCurrentLineup.current.values()).forEach(
      (animation) => {
        animation.pause();
      }
    );
  }, [setIsPlayAllRoutineActive]);

  const resetAnimations = useCallback(() => {
    Array.from(animationsForCurrentLineup.current.values()).forEach(
      (animation) => {
        animation.kill();
      }
    );
    animationsForCurrentLineup.current.clear();
  }, [animationsForCurrentLineup]);

  const changePlaySpeed = useCallback(
    (speed: number) => {
      setPlaySpeed(speed);
      resetAnimationsToStart();
      pauseAnimations();
    },
    [pauseAnimations, resetAnimationsToStart]
  );
  // eslint-disable-next-line
  const getCurrentTimeOfAnimation = () => {
    const anyAnimation = Array.from(
      animationsForCurrentLineup.current.values()
    )[0];
    if (anyAnimation) return anyAnimation.time();
    return 0;
  };

  const playNextAnimation = useCallback(
    (activityId: string) => {
      setCurrentTime(0);
      const wasChangedToNextActivity = changeToNextActivity(activityId);
      if (wasChangedToNextActivity) {
        setTimeout(() => {
          playAnimations();
        }, 100);
      } else {
        setIsPlayAllRoutineActive(false);
        pauseAnimations();
        resetAnimationsToStart();
      }
    },
    [
      changeToNextActivity,
      pauseAnimations,
      playAnimations,
      resetAnimationsToStart,
      setIsPlayAllRoutineActive
    ]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const time = getCurrentTimeOfAnimation();
      if (currentActivity && time < currentActivity.counts)
        setCurrentTime(time);
      else if (currentActivity)
        setCurrentTime(calculateStartTimeForGivenActivity(currentActivity));
    }, 1000 / playSpeed);
    if (!isPlayAllRoutineActive) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [
    calculateStartTimeForGivenActivity,
    currentActivity,
    getCurrentTimeOfAnimation,
    isPlayAllRoutineActive,
    playSpeed
  ]);

  const context: AnimationContextType = useMemo(
    () => ({
      resetAnimations,
      playAnimations,
      pauseAnimations,
      saveAnimationForCheerleader,
      playNextAnimation,
      resetAnimationsToStart,
      getCurrentTimeOfAnimation,
      currentTime,
      setCurrentTime,
      playSpeed,
      changePlaySpeed
    }),
    [
      changePlaySpeed,
      currentTime,
      pauseAnimations,
      playAnimations,
      playNextAnimation,
      playSpeed,
      resetAnimations,
      resetAnimationsToStart,
      saveAnimationForCheerleader
    ]
  );

  return (
    <AnimationContext.Provider value={context}>
      {children}
    </AnimationContext.Provider>
  );
};

export default AnimationContextProvider;
