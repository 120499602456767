import { useContext, useMemo } from "react";
import { RoutineContext } from "../state/RoutineContext";
import { Cheerleader } from "../api/types/team.types";
import { TeamContext } from "../state/TeamContext";
import useActivityCollection from "./useActivityCollection";

const useRoutineHandler = () => {
  const {
    activities,
    currentActivity,
    startTimeForCurrentActivity,
    saveCurrentLineupEntriesAndChangeCurrentActivity,
    lineupEntries,
    selectedRoutine,
    calculateStartTimeForGivenActivity
  } = useContext(RoutineContext);
  const { findHighestPositionInRoutine } = useActivityCollection();
  const { cheerleaders } = useContext(TeamContext);

  const getCurrentLastCount = useMemo(() => {
    const highestPosition = findHighestPositionInRoutine();

    const latestActivity = activities.find(
      (activity) => activity.positionInRoutine === highestPosition
    );
    return latestActivity
      ? latestActivity.positionInRoutine + latestActivity.counts
      : undefined;
  }, [activities, findHighestPositionInRoutine]);

  const handleCountChange = (currentCount: number) => {
    const matchingActivity = activities.find(
      (activity) =>
        calculateStartTimeForGivenActivity(activity) <= currentCount &&
        calculateStartTimeForGivenActivity(activity) + activity.counts >
          currentCount
    );

    if (!matchingActivity) {
      saveCurrentLineupEntriesAndChangeCurrentActivity(undefined);
      return;
    }
    calculateStartTimeForGivenActivity(matchingActivity) !==
      startTimeForCurrentActivity &&
      saveCurrentLineupEntriesAndChangeCurrentActivity(matchingActivity);
  };

  const getCheersForLineup = () => {
    const cheersForLineup: Map<string, Cheerleader> = new Map();
    if (!currentActivity) return cheersForLineup;
    const filteredLineupEntries = () => {
      if (selectedRoutine && selectedRoutine.excludedCheerleader)
        return lineupEntries.filter(
          (lineup) =>
            selectedRoutine.excludedCheerleader?.find(
              (excluded) => excluded === lineup.cheerId
            )
        );
      return lineupEntries;
    };
    filteredLineupEntries().forEach((lineupEntry) => {
      const cheerleader = cheerleaders.find(
        (cheer) => cheer.id === lineupEntry.cheerId
      );
      if (!cheerleader) return;
      cheersForLineup.set(lineupEntry.id, cheerleader);
    });
    return cheersForLineup;
  };

  return { handleCountChange, getCheersForLineup, getCurrentLastCount };
};

export default useRoutineHandler;
