import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage
} from "@ionic/react";
import { addOutline } from "ionicons/icons";
import { BlockPicker } from "react-color";
import { addCheerleader } from "../../../../api/collections/cheerleaderCollection";
import { TeamContext } from "../../../../state/TeamContext";
import createCheerleaderInitials from "../../../../util/initialeGenerator";
import { AnimationContext } from "../../../../state/AnimationContext";
import { SettingsContext } from "../../../../state/SettingsContext";
import keyPressListener from "../../../../util/hotKey";
import ModalHeader from "../../ModalHeader";

const tinyColor = require("tinycolor2");

interface CheerLeaderAddPopOverProps {
  popoverOpen: boolean;
  setPopoverOpen: (isPopoverOpen: boolean) => void;
}

const CheerLeaderAddPopOver: React.FC<CheerLeaderAddPopOverProps> = (props) => {
  const [name, setName] = useState("");
  const [newColor, setNewColor] = useState(tinyColor.random().toHexString());
  const { selectedTeam, cheerleaders } = useContext(TeamContext);
  const refPopover = useRef<HTMLIonModalElement>(null);
  const resetAllFields = useCallback(() => {
    setName("");
    setNewColor(tinyColor.random().toHexString());
    props.setPopoverOpen(false);
  }, [props]);
  const { resetAnimationsToStart, pauseAnimations } =
    useContext(AnimationContext);
  const { setIsPlayAllRoutineActive } = useContext(SettingsContext);
  const pauseAndReset = useCallback(() => {
    setIsPlayAllRoutineActive(false);
    pauseAnimations();
    resetAnimationsToStart();
  }, [pauseAnimations, resetAnimationsToStart, setIsPlayAllRoutineActive]);

  const addCheer = useCallback(async () => {
    if (!selectedTeam || !name.length) return;
    pauseAndReset();
    resetAllFields();
    await addCheerleader(selectedTeam.id, {
      color: newColor,
      name,
      initials: createCheerleaderInitials(
        name,
        cheerleaders.map((cheer) => cheer.initials)
      ),
      skills: []
    });
  }, [
    cheerleaders,
    name,
    newColor,
    pauseAndReset,
    resetAllFields,
    selectedTeam
  ]);

  useLayoutEffect(() => {
    if (props.popoverOpen) {
      const onEnterPress = keyPressListener(
        "Enter",
        addCheer,
        refPopover.current
      );
      return () => {
        onEnterPress();
      };
    }
    return () => {};
  }, [addCheer, props.popoverOpen]);
  return (
    <IonModal
      isOpen={props.popoverOpen}
      onDidDismiss={() => {
        props.setPopoverOpen(false);
        resetAllFields();
      }}
      ref={refPopover}
      style={{ "--max-width": "350px", "--max-height": "350px" }}
    >
      <IonPage>
        <ModalHeader
          headerText="create cheerleader"
          cancelFunction={() => props.setPopoverOpen(false)}
        />
        <IonContent>
          <IonItem>
            <IonInput
              type="text"
              value={name}
              placeholder="enter name"
              onIonChange={(event) =>
                event.detail.value && setName(event.detail.value)
              }
            />
          </IonItem>
          <IonItem>
            <BlockPicker
              color={newColor}
              onChangeComplete={(color) => setNewColor(color.hex)}
              width="inherit"
            />
          </IonItem>
          <IonItem>
            <IonButton
              onClick={addCheer}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            >
              <IonLabel>add to team</IonLabel>
              <IonIcon slot="icon-only" icon={addOutline} />
            </IonButton>
          </IonItem>
        </IonContent>
      </IonPage>
    </IonModal>
  );
};

export default CheerLeaderAddPopOver;
