import React, { useMemo } from "react";
import { LineupEntry, Position } from "../../api/types/routine.types";

const tinyColor = require("tinycolor2");

interface PersonCircleAdditionsProps {
  lineupEntry: LineupEntry;
  isActive: boolean | undefined;
  pathId: string;
  positionsForPrevAndNextLineup: {
    nextPos: React.MutableRefObject<Position | undefined>;
    prevPos: React.MutableRefObject<Position | undefined>;
  };
  radius: number;
  colorOfCheer: string;
}

const PersonCircleAdditions: React.FC<PersonCircleAdditionsProps> = (props) => {
  const renderAdditions = useMemo(
    () => (
      <>
        {props.lineupEntry.path.length && (
          <path
            stroke="green"
            strokeWidth="2px"
            d={props.lineupEntry.path}
            fill="none"
            strokeDasharray="10,10"
            id={`${props.pathId}_old`}
          />
        )}
        {props.positionsForPrevAndNextLineup.prevPos.current && (
          <g>
            <circle
              opacity="50%"
              cx={props.positionsForPrevAndNextLineup.prevPos.current.x}
              cy={props.positionsForPrevAndNextLineup.prevPos.current.y}
              r={props.radius + 5}
              stroke="green"
              strokeWidth="5"
              fill={tinyColor(props.colorOfCheer).lighten().toString()}
            />
            <text
              x={props.positionsForPrevAndNextLineup.prevPos.current.x}
              y={props.positionsForPrevAndNextLineup.prevPos.current.y}
              textAnchor="middle"
              fill="black"
              dy=".3em"
              onClick={(event) => event.preventDefault()}
            >
              START
            </text>
          </g>
        )}
        {props.positionsForPrevAndNextLineup.nextPos.current && (
          <g>
            <circle
              opacity="50%"
              cx={props.positionsForPrevAndNextLineup.nextPos.current.x}
              cy={props.positionsForPrevAndNextLineup.nextPos.current.y}
              r={props.radius + 5}
              stroke="red"
              strokeWidth="5"
              fill={tinyColor(props.colorOfCheer).darken().toString()}
            />
            <text
              x={props.positionsForPrevAndNextLineup.nextPos.current.x}
              y={props.positionsForPrevAndNextLineup.nextPos.current.y}
              textAnchor="middle"
              fill="black"
              dy=".3em"
              onClick={(event) => event.preventDefault()}
            >
              END
            </text>
          </g>
        )}
      </>
    ),
    [props]
  );
  return props.isActive ? renderAdditions : null;
};

export default PersonCircleAdditions;
