import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage
} from "@ionic/react";
import { save, trashOutline } from "ionicons/icons";
import { Cheerleader } from "../../../../api/types/team.types";
import { removeCheerleader } from "../../../../api/collections/cheerleaderCollection";
import { TeamContext } from "../../../../state/TeamContext";
import { AnimationContext } from "../../../../state/AnimationContext";
import { SettingsContext } from "../../../../state/SettingsContext";
import useCheerSettings from "../../../../hooks/useCheerSettings";
import { RoutineContext } from "../../../../state/RoutineContext";
import useLineupEntryCollection from "../../../../hooks/useLineupEntryCollection";
import useShowDeleteDialog from "../../../dialogs/useShowDeleteDialog";
import ModalHeader from "../../ModalHeader";

interface CheerleaderConfigurePopOverProps {
  popoverOpen: boolean;
  setPopoverOpen: (open: boolean) => void;
  selectedCheerleader?: Cheerleader;
}

const CheerleaderConfigurePopOver: React.FC<
  CheerleaderConfigurePopOverProps
> = (props) => {
  const [name, setName] = useState<string | undefined>();
  const [initials, setInitials] = useState<string | undefined>();
  const [stunt, setStunt] = useState<string | undefined>();
  const { selectedCheerleader, popoverOpen } = props;
  const { openDeleteDialog } = useShowDeleteDialog();
  const { updateSelectedCheerleader } = useCheerSettings();
  const { setSelectedCheerleader, selectedTeam } = useContext(TeamContext);
  const { resetAnimationsToStart, pauseAnimations } =
    useContext(AnimationContext);
  const { setIsPlayAllRoutineActive } = useContext(SettingsContext);
  const { lineupEntriesForCurrentActivity, currentActivity } =
    useContext(RoutineContext);
  const { updateLineUp } = useLineupEntryCollection();
  const lineUpForCheer = lineupEntriesForCurrentActivity.find(
    (lineUp) => lineUp.cheerId === props.selectedCheerleader?.id
  );
  useEffect(() => {
    if (!popoverOpen) setName(undefined);
    setInitials(undefined);
    setStunt(undefined);
    if (!selectedCheerleader) return;
    setName(selectedCheerleader.name);
    setInitials(selectedCheerleader.initials);
    if (!lineUpForCheer) return;
    setStunt(lineUpForCheer.stuntDuringActivity);
  }, [lineUpForCheer, popoverOpen, selectedCheerleader]);
  const pauseAndReset = () => {
    setIsPlayAllRoutineActive(false);
    pauseAnimations();
    resetAnimationsToStart();
  };
  const deleteCheer = async () => {
    if (selectedTeam && selectedCheerleader) {
      await removeCheerleader(selectedTeam.id, selectedCheerleader.id);
      setSelectedCheerleader(undefined);
      pauseAndReset();
    }
  };

  function hasStuntChanged(stuntDuringActivity: string | undefined) {
    return stunt !== stuntDuringActivity;
  }

  function hasCheerleaderChanged(cheerleader: Cheerleader) {
    return initials === cheerleader.initials && name === cheerleader.name;
  }

  const updateCheer = async () => {
    props.setPopoverOpen(false);
    if (lineUpForCheer && hasStuntChanged(lineUpForCheer.stuntDuringActivity))
      await updateLineUp(lineUpForCheer, {
        stuntDuringActivity: stunt
      });
    if (!selectedCheerleader || hasCheerleaderChanged(selectedCheerleader))
      return;
    updateSelectedCheerleader(selectedCheerleader!, {
      initials: initials || selectedCheerleader.initials,
      name: name || selectedCheerleader?.name
    });
  };

  return (
    <IonModal
      id="cheerleaderPopover"
      isOpen={props.popoverOpen}
      onDidDismiss={() => {
        props.setPopoverOpen(false);
      }}
      style={{ "--max-width": "250px", "--max-height": "300px" }}
    >
      <IonPage>
        <ModalHeader
          headerText="change cheerleader settings"
          cancelFunction={() => props.setPopoverOpen(false)}
        />
        <IonContent>
          <IonList>
            <IonItem>
              <IonInput
                label="name:"
                labelPlacement="fixed"
                maxlength={25}
                value={name}
                onIonChange={(e) => {
                  if (typeof e.target.value === "string")
                    setName(e.target.value);
                }}
              />
            </IonItem>
            <IonItem>
              <IonInput
                label="initials:"
                labelPlacement="fixed"
                maxlength={3}
                value={initials}
                onIonChange={(e) => {
                  if (typeof e.target.value === "string")
                    setInitials(e.target.value);
                }}
              />
            </IonItem>
            {currentActivity && (
              <IonItem>
                <IonInput
                  label="stunt:"
                  labelPlacement="fixed"
                  maxlength={25}
                  value={stunt}
                  onIonChange={(e) => {
                    if (typeof e.target.value === "string")
                      setStunt(e.target.value);
                  }}
                />
              </IonItem>
            )}
          </IonList>
        </IonContent>
        <IonFooter>
          <IonButton
            onClick={async () => {
              props.setPopoverOpen(false);
              await openDeleteDialog(selectedCheerleader!.name, () =>
                deleteCheer()
              );
            }}
            color="danger"
          >
            <IonLabel slot="start">delete</IonLabel>
            <IonIcon slot="end" icon={trashOutline} />
          </IonButton>
          <IonButton className="ion-float-right" onClick={updateCheer}>
            <IonLabel slot="start">update</IonLabel>
            <IonIcon slot="end" icon={save} />
          </IonButton>
        </IonFooter>
      </IonPage>
    </IonModal>
  );
};

export default CheerleaderConfigurePopOver;
