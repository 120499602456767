import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption
} from "@ionic/react";
import { Routine } from "../../api/types/routine.types";
import keyPressListener from "../../util/hotKey";
import ModalHeader from "../menu/ModalHeader";
import { Carpet } from "../../api/types/carpet.types";

interface RoutineCreationPopoverProps {
  isOpen: boolean;
  togglePopOver: () => void;
  onButtonClick: (name: string, maxCounts: number, carpetType: Carpet) => void;
  setCreationPopUpVisible: React.Dispatch<React.SetStateAction<boolean>>;
  buttonLabel: string;
}

interface CreateRoutine extends Pick<Routine, "name" | "maxCounts"> {
  carpetType: Carpet;
}

const RoutineCreationPopover: React.FC<RoutineCreationPopoverProps> = (
  props
) => {
  const [newRoutine, setNewRoutine] = useState<CreateRoutine>({
    name: "",
    carpetType: { length: 14, width: 2, countOfCarpets: 7 } as Carpet,
    maxCounts: 0
  });
  const validateInteger = (number: string) =>
    number.match(/^([0-9]|[1-9][0-9])$/);
  const validateFloat = (number: string) =>
    number.match(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/);

  const validate = (value: string) => {
    if (value === "") return false;
    return validateInteger(value) !== null;
  };

  const refPopover = useRef<HTMLIonModalElement>(null);
  const selectOptionBeatCount = "beatCount";
  const selectOptionEightBeat = "eightBeat";
  const [selectOption, setSelectOption] = useState(selectOptionEightBeat);
  const [memorizedMaxCounts, setMemorizedMaxCounts] = useState(
    newRoutine.maxCounts
  );

  const createRoutine = useCallback(() => {
    console.log(newRoutine);
    if (
      newRoutine.name.length &&
      newRoutine.maxCounts &&
      newRoutine.carpetType.length
    ) {
      props.onButtonClick(
        newRoutine.name,
        newRoutine.maxCounts,
        newRoutine.carpetType as Carpet
      );
      props.setCreationPopUpVisible(false);
      props.togglePopOver();
    }
  }, [newRoutine, props]);

  useLayoutEffect(() => {
    if (!props.isOpen) return () => {};
    const onEnterPress = keyPressListener(
      "Enter",
      createRoutine,
      refPopover.current
    );
    return () => {
      onEnterPress();
    };
  }, [createRoutine, props.isOpen]);

  const handleChangeMaxCounts = (input: string | number | null | undefined) => {
    if (typeof input === "string") {
      newRoutine.maxCounts = parseInt(input, 10);
    }
    setMemorizedMaxCounts(newRoutine.maxCounts);
  };

  return (
    <IonModal
      ref={refPopover}
      isOpen={props.isOpen}
      onDidDismiss={() => props.togglePopOver()}
    >
      <IonPage>
        <ModalHeader
          cancelFunction={() => {
            props.setCreationPopUpVisible(false);
            props.togglePopOver();
          }}
          headerText="Create routine"
        />
        <IonContent>
          <IonGrid className="ion-margin-horizontal">
            <IonRow>
              <IonLabel
                className="ion-margin-top"
                style={{ fontWeight: "bold" }}
              >
                name of routine
              </IonLabel>
            </IonRow>
            <IonRow>
              <IonItem lines="none" className="flex-grow-1">
                <IonInput
                  placeholder="enter name"
                  type="text"
                  onIonInput={(input) => {
                    if (typeof input.detail.value === "string") {
                      newRoutine.name = input.detail.value;
                    }
                  }}
                />
              </IonItem>
            </IonRow>
            <IonRow>
              <IonLabel
                className="ion-margin-top"
                style={{ fontWeight: "bold" }}
              >
                beat selection
              </IonLabel>
            </IonRow>
            <IonRow>
              <IonItem lines="none" className="flex-grow-1">
                <IonSelect
                  value={selectOption}
                  onIonChange={(e) => {
                    setSelectOption(e.detail.value);
                    if (e.detail.value === selectOptionEightBeat)
                      newRoutine.maxCounts /= 8;
                    else newRoutine.maxCounts *= 8;
                    setMemorizedMaxCounts(newRoutine.maxCounts);
                  }}
                >
                  <IonSelectOption value={selectOptionEightBeat}>
                    8 counts
                  </IonSelectOption>
                  <IonSelectOption value={selectOptionBeatCount}>
                    total beat count
                  </IonSelectOption>
                </IonSelect>
                <IonInput
                  placeholder="enter beat count"
                  type="number"
                  onIonInput={(input) => {
                    handleChangeMaxCounts(input.detail.value);
                  }}
                  value={memorizedMaxCounts}
                />
              </IonItem>
            </IonRow>
            <IonRow>
              <IonLabel
                className="ion-margin-top"
                style={{ fontWeight: "bold" }}
              >
                carpet dimensions
              </IonLabel>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonItem lines="none">
                  <IonInput
                    placeholder="Enter number"
                    label="Total count of carpets"
                    labelPlacement="stacked"
                    onIonInput={(e) => {
                      if (validate(e.target.value as string))
                        setNewRoutine(
                          (prevState) =>
                            ({
                              ...prevState,
                              carpetType: {
                                ...prevState.carpetType,
                                countOfCarpets: e.target.value
                              }
                            }) as CreateRoutine
                        );
                    }}
                    min={1}
                    value={newRoutine.carpetType.countOfCarpets}
                  />
                </IonItem>
              </IonCol>
              <IonCol size="12">
                <IonItem lines="none">
                  <IonInput
                    label="length of carpet"
                    labelPlacement="stacked"
                    placeholder="Enter number in m"
                    onIonInput={(e) => {
                      const input = e.target.value as string;
                      if (input.length && validateFloat(input))
                        setNewRoutine(
                          (prevState) =>
                            ({
                              ...prevState,
                              carpetType: {
                                ...prevState.carpetType,
                                length: e.target.value
                              }
                            }) as CreateRoutine
                        );
                    }}
                    min={1}
                    value={newRoutine.carpetType.length}
                  />
                </IonItem>
              </IonCol>
              <IonCol size="12">
                <IonItem lines="none">
                  <IonInput
                    label="width of carpet"
                    labelPlacement="stacked"
                    placeholder="Enter number in m"
                    onIonInput={(e) => {
                      const input = e.target.value as string;
                      if (input.length && validateFloat(input))
                        setNewRoutine(
                          (prevState) =>
                            ({
                              ...prevState,
                              carpetType: {
                                ...prevState.carpetType,
                                width: e.target.value
                              }
                            }) as CreateRoutine
                        );
                    }}
                    min={1}
                    value={newRoutine.carpetType.width}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonButton
                color="secondary"
                expand="block"
                shape="round"
                fill="solid"
                onClick={() => {
                  if (selectOption === selectOptionEightBeat)
                    newRoutine.maxCounts *= 8;
                  createRoutine();
                }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              >
                {props.buttonLabel}
              </IonButton>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </IonModal>
  );
};

export default RoutineCreationPopover;
