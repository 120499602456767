import {
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonRow
} from "@ionic/react";
import { addCircleOutline } from "ionicons/icons";
import React, { useContext, useMemo, useState } from "react";
import ActivityItem from "./ActivityItem";
import AddActivityModal from "./AddActivityModal";
import { RoutineContext } from "../../../state/RoutineContext";
import useAlertWithOkButton from "../../../hooks/alerts/useAlertWithOkButton";

function ActivityList() {
  const [isOpen, setIsOpen] = useState(false);
  const { activities, selectedRoutine, usedActivityCounts } =
    useContext(RoutineContext);
  const presentAlert = useAlertWithOkButton();

  const usedToMuchActivityCounts = useMemo(() => {
    if (!selectedRoutine) return "";
    return usedActivityCounts > selectedRoutine.maxCounts ? "red" : "";
  }, [selectedRoutine, usedActivityCounts]);

  const eighthOfCount = (numberOfCounts: number) => {
    const result = numberOfCounts / 8;
    return result.toFixed(1);
  };

  return (
    <>
      <IonList id="position-list" role="list" className="ion-padding-top">
        <IonItem lines="none" style={{ "--min-height": "10px" }}>
          <IonNote style={{ color: usedToMuchActivityCounts }}>
            8 count:{" "}
            {selectedRoutine
              ? `${eighthOfCount(usedActivityCounts)} von ${eighthOfCount(
                  selectedRoutine.maxCounts
                )}`
              : "no routine selected"}
          </IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonCol size="12">
            <IonRow className="ion-justify-content-center">
              <IonLabel className="ion-text-center">section name</IonLabel>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonLabel className="ion-text-center">
                <small>beats | 8Count</small>
              </IonLabel>
            </IonRow>
          </IonCol>
        </IonItem>
        {activities.map((activity) => (
          <ActivityItem
            key={`${activity.id}`}
            activity={activity}
            eightOfCount={eighthOfCount}
          />
        ))}
        <IonItem
          lines="none"
          mode="md"
          button
          onClick={() => {
            if (!selectedRoutine) {
              presentAlert("Fehler", "Bitte zuerst eine Routine auswählen");
              return;
            }
            setIsOpen(true);
          }}
        >
          <IonIcon color="primary" slot="start" icon={addCircleOutline} />
          <IonLabel>add activity</IonLabel>
        </IonItem>
      </IonList>
      <AddActivityModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

export default ActivityList;
