import React from "react";
import { IonButton, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { eye, eyeOff } from "ionicons/icons";
import ColorPicker from "./ColorPicker";
import useCheerSettings from "../../../hooks/useCheerSettings";
import { Cheerleader } from "../../../api/types/team.types";

interface CheerleaderListItemProps {
  cheerleader: Cheerleader;
  initiales: string;
  isHidden: boolean;
  setPopoverOpen: (open: boolean) => void;
  setSelectedCheerleader: (cheerleader?: Cheerleader | undefined) => void;
}

const CheerleaderListItem: React.FC<CheerleaderListItemProps> = ({
  cheerleader,
  initiales,
  isHidden,
  setPopoverOpen,
  setSelectedCheerleader
}) => {
  const { updateSelectedCheerleader, updateCheerVisibility } =
    useCheerSettings();

  return (
    <IonItem
      lines="none"
      mode="md"
      key={`${cheerleader.name}${cheerleader.id.substring(0, 3)}`}
      onClick={(e) => {
        e.stopPropagation();
        setSelectedCheerleader(cheerleader);
        setPopoverOpen(true);
      }}
      button
    >
      <ColorPicker
        cheerleaderInitials={initiales}
        currentColor={cheerleader.color || "black"}
        onColorChange={(newColor) =>
          updateSelectedCheerleader(cheerleader, { color: newColor })
        }
      />
      <IonLabel>{cheerleader.name}</IonLabel>

      <IonButton
        slot="end"
        shape="round"
        fill="outline"
        onClick={(e) => {
          e.stopPropagation();
          updateCheerVisibility(cheerleader, !isHidden);
        }}
      >
        <IonIcon
          slot="icon-only"
          icon={isHidden ? eyeOff : eye}
          color="primary"
        />
      </IonButton>
    </IonItem>
  );
};

export default CheerleaderListItem;
