enum CollectionName {
  USERS = "users",
  TEAMS = "teams",
  CHEERLEADERS = "cheerleaders",
  GROUPS = "groups",
  ROUTINES = "routines",
  ACTIVITIES = "activities",
  LINEUP_ENTRIES = "lineupEntries"
}

export default CollectionName;
