import React, { useContext, useRef, useState } from "react";
import { IonButton, IonPopover } from "@ionic/react";
import useTeamCollection from "../../../hooks/useTeamCollection";
import TeamCreationForm from "../TeamCreationForm";
import { UserContext } from "../../../state/UserContext";
import ModalHeader from "../../../components/menu/ModalHeader";

const TeamCreationButton = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef<HTMLIonPopoverElement>(null);
  const { createTeam } = useTeamCollection();
  const { currentUser } = useContext(UserContext);

  const openPopover = (
    e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
  ) => {
    if (e) popoverRef.current!.event = e;
    setPopoverOpen(true);
  };

  const onTeamCreate = async (name: string) => {
    if (!currentUser) return;
    await createTeam({
      name
    });
  };

  return (
    <>
      <IonButton
        color="primary"
        expand="block"
        shape="round"
        fill="solid"
        onClick={openPopover}
      >
        Team
      </IonButton>
      <IonPopover
        ref={popoverRef}
        isOpen={popoverOpen}
        onDidDismiss={() => setPopoverOpen(false)}
      >
        <ModalHeader
          cancelFunction={() => setPopoverOpen(false)}
          headerText="choose team"
        />
        <TeamCreationForm
          buttonLabel="create"
          onTeamCreationButtonClick={onTeamCreate}
          togglePopOver={() => setPopoverOpen(false)}
        />
      </IonPopover>
    </>
  );
};

export default TeamCreationButton;
