import React, {
  RefObject,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import tinyColor from "tinycolor2";
import { Capacitor } from "@capacitor/core";
import { LineupEntry } from "../../api/types/routine.types";
import { Cheerleader } from "../../api/types/team.types";
import { getCoordinateAtStartOfPath } from "../../util/pathUtilities";
import useMovePersonCircle from "../../hooks/useMovePersonCircle";
import pathAnimation from "../../hooks/pathAnimation";
import { AnimationContext } from "../../state/AnimationContext";
import PersonCircleAdditions from "./PersonCircleAdditions";

interface PersonCircleProps {
  lineupEntry: LineupEntry;
  cheerleader: Cheerleader;
  svgRef: RefObject<SVGSVGElement>;
  isActive?: boolean;
}

const PersonCircle: React.FC<PersonCircleProps> = (props) => {
  const {
    path,
    setPath,
    position,
    setPosition,
    radius,
    currentActivity,
    handlePointerMove,
    handlePointerDown,
    handlePointerUp,
    showStunts,
    isActive,
    shouldDrawLineForNoGivenPath,
    positionsForPrevAndNextLineup,
    isPathStartAndEndEqualToSurroundingActivity
  } = useMovePersonCircle({
    isActive: props.isActive || false,
    svgRef: props.svgRef,
    lineupEntry: props.lineupEntry
  });

  const { saveAnimationForCheerleader } = useContext(AnimationContext);
  const isMobile = Capacitor.isNativePlatform();
  const targetID = `${props.lineupEntry.cheerId}-${props.lineupEntry.id}-id`;
  const pathId = `${props.lineupEntry.cheerId}-${props.lineupEntry.id}-path`;
  const pathRef = useRef<SVGPathElement>(null);
  const circleRef = useRef<SVGGElement>(null);
  const [pathStrokeWidth, setPathStrokeWidth] = useState(2);

  const { playNextAnimation, playAnimations } = useContext(AnimationContext);
  useLayoutEffect(() => {
    const animation = pathAnimation(
      {
        durationInSec: currentActivity?.counts,
        pathRef,
        targetRef: circleRef
      },
      () => playNextAnimation(props.lineupEntry.activityId)
    );
    if (animation) saveAnimationForCheerleader(animation, pathId);
  }, [
    currentActivity?.counts,
    path,
    pathId,
    playAnimations,
    playNextAnimation,
    props.lineupEntry.activityId,
    saveAnimationForCheerleader,
    targetID
  ]);

  useEffect(() => {
    if (shouldDrawLineForNoGivenPath.shouldDraw) {
      const { previousLineupEnd, nextLineupStart } =
        shouldDrawLineForNoGivenPath;
      setPosition(previousLineupEnd!);
      setPath(
        `M ${previousLineupEnd!.x} ${previousLineupEnd!.y} L ${
          nextLineupStart!.x
        } ${nextLineupStart!.y}`
      );
    } else {
      setPosition(getCoordinateAtStartOfPath(props.lineupEntry.path));
      setPath(props.lineupEntry.path);
    }
  }, [
    currentActivity,
    props.lineupEntry.path,
    setPath,
    setPosition,
    shouldDrawLineForNoGivenPath
  ]);

  useLayoutEffect(() => {
    const pathSvg = document.getElementById(
      `${props.lineupEntry.cheerId}-${props.lineupEntry.id}-group`
    );
    if (pathSvg) {
      pathSvg.addEventListener("mouseover", () => {
        setPathStrokeWidth(6);
      });
      pathSvg.addEventListener("mouseout", () => {
        setPathStrokeWidth(2);
      });
    }
  }, [pathId, props.lineupEntry.cheerId, props.lineupEntry.id]);

  return (
    position && (
      <g id={`${props.lineupEntry.cheerId}-${props.lineupEntry.id}-group`}>
        <path
          ref={pathRef}
          stroke={isPathStartAndEndEqualToSurroundingActivity ? "green" : "red"}
          strokeWidth={`${pathStrokeWidth}px`}
          d={path}
          fill="none"
          id={pathId}
        />
        <PersonCircleAdditions
          lineupEntry={props.lineupEntry}
          isActive={isActive}
          pathId={pathId}
          positionsForPrevAndNextLineup={positionsForPrevAndNextLineup}
          radius={radius}
          colorOfCheer={props.cheerleader.color}
        />
        <g
          id={targetID}
          ref={circleRef}
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}
          onPointerMove={handlePointerMove}
        >
          {position.x && position.y && (
            <g transform={`translate(${position.x}, ${position.y})`}>
              <circle
                r={radius}
                stroke={isActive ? "black" : "grey"}
                strokeWidth={isActive ? "3" : "1"}
                fill={props.cheerleader.color}
              />
              {isActive && isMobile && (
                <circle
                  r={radius + 45}
                  stroke="black"
                  strokeWidth={10}
                  fill="transparent"
                  strokeOpacity="0.5"
                />
              )}
            </g>
          )}
          <text
            x={position.x}
            y={position.y}
            textAnchor="middle"
            fill={
              tinyColor(props.cheerleader.color).isLight() ? "black" : "white"
            }
            dy=".3em"
            onClick={(event) => event.preventDefault()}
          >
            {props.cheerleader.initials.toUpperCase()}
          </text>
          {showStunts &&
            props.lineupEntry.stuntDuringActivity &&
            position.x &&
            position.y && (
              <text
                x={position.x!}
                y={position.y! + 17}
                textAnchor="middle"
                fill={
                  tinyColor(props.cheerleader.color).isLight()
                    ? "black"
                    : "white"
                }
              >
                {props.lineupEntry.stuntDuringActivity}
              </text>
            )}
        </g>
      </g>
    )
  );
};

export default PersonCircle;
